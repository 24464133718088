/* eslint-disable */
// @ts-nocheck
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/de';

import { addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import { connect } from 'react-redux';

import { getConfig } from '../contextual-config';
import { api } from '../util/request';

addLocaleData([...en, ...de]);

const CHANGE_LOCALE_START = 'locale/CHANGE_LOCALE_START';
const CHANGE_LOCALE_SUCCESS = 'locale/CHANGE_LOCALE_SUCCESS';
const CHANGE_LOCALE_ERROR = 'locale/CHANGE_LOCALE_ERROR';

export function reducer(
  state = {
    locale: getConfig('defaultLanguage'),
  },
  action,
) {
  if (action.type === CHANGE_LOCALE_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === CHANGE_LOCALE_SUCCESS) {
    const { locale, messages } = action;
    return {
      ...state,
      loading: false,
      locale,
      messages,
    };
  }

  if (action.type === CHANGE_LOCALE_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
}

export const changeLocale = locale => async dispatch => {
  dispatch({
    type: CHANGE_LOCALE_START,
  });

  try {
    const response = await api
      .get(`/v1/resource/bundle/${getConfig('resourceBundleName')}`)
      .query({
        language: locale,
        brand: `A${getConfig('brand')}`,
      });

    return dispatch({
      type: CHANGE_LOCALE_SUCCESS,
      messages: response.body,
      locale,
    });
  } catch (error) {
    return dispatch({
      type: CHANGE_LOCALE_ERROR,
      error,
    });
  }
};

export const withLocaleMessage = connect(state => ({
  locale: state.locale.locale,
  messages: state.locale.messages,
}));

export const persistence = {
  save({ locale }) {
    return {
      locale: locale.locale,
    };
  },

  load({ locale }) {
    return {
      locale: {
        locale,
      },
    };
  },
};
