/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import OpeningHoursTable from '../OpeningHoursTable';

export default function OpeningHours({ details }) {
  if (
    details.sales == null ||
    details.sales.openingTimes == null ||
    details.sales.openingTimes.length === 0
  ) {
    return null;
  }

  return (
    <>
      <h5 className="row my-3">
        <FormattedMessage
          id="details.openingTitle"
          defaultMessage="Opening Hours"
        />
      </h5>
      <OpeningHoursTable hours={details.sales.openingTimes} />
    </>
  );
}

OpeningHours.propTypes = {
  details: PropTypes.shape({
    sales: PropTypes.shape({
      openingTimes: PropTypes.arrayOf(
        PropTypes.shape({
          dayOfWeek: PropTypes.number,
          openingHour: PropTypes.number,
          openingMinutes: PropTypes.number,
          closingHour: PropTypes.number,
          closingMinutes: PropTypes.number,
        }),
      ),
    }),
  }),
};
