import './style.scss';

import Toggle from '@atlaskit/toggle';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useAcceptAllCookiesAndCloseBanner,
  useAnalyticsCookies,
  useCookiesBannerVisible,
  useCookiesModalVisible,
  useFunctionalCookies,
  useGoogleMapsCookies,
  useGoogleReCaptchaCookies,
  useUseCookiesBanner,
  useUseCookiesModal,
} from './cookies';

function useBodyOverflow() {
  const modal = useCookiesModalVisible();
  const banner = useCookiesBannerVisible();
  useEffect(() => {
    if (modal || banner) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [modal, banner]);
}

// const COOKIE = 'cookieconsent';

function CookieItemGoogleMaps() {
  const { value, setValue } = useGoogleMapsCookies();
  return (
    <div className="cookie-item">
      <div className="cookie-text">
        <h6 className="font-weight-bold">Google Maps</h6>
        <p>
          Dieses Cookie ist notwendig um die Jura Händler auf einer Landkarte
          anzuzeigen und die Distanz von Ihrem oder dem angegebenen Standort zu
          berechnen.
        </p>
      </div>
      <Toggle
        isChecked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </div>
  );
}
function CookieItemGoogleReCaptcha() {
  const { value, setValue } = useGoogleReCaptchaCookies();
  return (
    <div className="cookie-item">
      <div className="cookie-text">
        <h6 className="font-weight-bold">Google ReCaptcha</h6>
        <p>
          Dieses Cookie dient zur Sicherheit und wird gesetzt, wenn Sie über
          diese Webseite Anfragen an einen Jura Händler senden.
        </p>
      </div>
      <Toggle
        isChecked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </div>
  );
}
// function CookieItemMarketing() {
//   const { value, setValue } = useMarketingCookies();
//
//   return (
//     <div className="cookie-item">
//       <div className="cookie-text">
//         <h6 className="font-weight-bold">Aktivieren von Marketing-Cookies</h6>
//         <p>
//           Diese Cookies werden verwendet, um die Wirksamkeit von Werbung zu
//           verfolgen, einen relevanteren Service zu bieten und Werbung besser auf
//           Ihre Interessen abzustimmen.
//         </p>
//       </div>
//       <Toggle
//         isChecked={value}
//         value={value ? 'true' : undefined}
//         onChange={() => setValue(!value)}
//       />
//     </div>
//   );
// }
function CookieItemFunctional() {
  const { value, setValue } = useFunctionalCookies();

  return (
    <div className="cookie-item">
      <div className="cookie-text">
        <h6 className="font-weight-bold">Funktional notwendiges Cookie</h6>
        <p>Dieses Cookie ist notwendig zum einwandfreien Betrieb der Seite.</p>
      </div>
      <Toggle
        isChecked={value}
        isDisabled
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </div>
  );
}
function CookieItemAnalytics() {
  const { value, setValue } = useAnalyticsCookies();
  return (
    <div className="cookie-item">
      <div className="cookie-text">
        <h6 className="font-weight-bold">Google Analytics</h6>
        <p>
          Dieses Cookie hilft uns zu verstehen, wie Besucher mit unserer Website
          interagieren, Fehler zu entdecken und eine bessere Gesamtanalyse zu
          erstellen.
        </p>
      </div>
      <Toggle
        isChecked={value}
        value={value ? 'true' : undefined}
        onChange={() => setValue(!value)}
      />
    </div>
  );
}

export function CookieBanner2023(): React.ReactElement | null {
  const { value: visible } = useUseCookiesBanner();
  const { toggle: toggleModal } = useUseCookiesModal();
  const onAcceptAll = useAcceptAllCookiesAndCloseBanner();
  useBodyOverflow();
  if (!visible) return null;
  return (
    <div className="cookie-modal-overlay">
      <div className="cookie-banner d-flex align-items-center">
        <div className="d-flex align-items-center flex-column">
          <p>
            Auf unserer Website werden Cookies verwendet. Cookies, die nicht nur
            dem technisch einwandfreien und sicheren Seitenbetrieb dienen,
            kommen ausschließlich mit Ihrer Einwilligung zum Einsatz. Sie können
            eine erteilte Einwilligung jederzeit widerrufen.
          </p>
          <div className="mx-auto p-3 text-justify d-flex">
            <div>
              <a
                href=" "
                data-toggle="modal"
                data-target="#DataPrivacy"
                className="text-black-50 mx-2"
              >
                <FormattedMessage
                  id="osm.dataPrivacy"
                  defaultMessage="Datenschutz"
                />
              </a>
            </div>
            <a
              href=" "
              data-toggle="modal"
              data-target="#ImprintModal"
              className="text-black-50 mx-2"
            >
              <FormattedMessage id="osm.imprint" defaultMessage="Impressum" />
            </a>
          </div>
        </div>
        <button
          type="button"
          className="outline-btn mx-4 px-3 py-1 m-2"
          onClick={toggleModal}
        >
          Einstellungen
        </button>
        <button
          type="button"
          className="accept-btn mx-4 px-3 py-1 border-0 text-white"
          onClick={onAcceptAll}
        >
          Alle akzeptieren
        </button>
      </div>
    </div>
  );
}
export function CookieConsent2023(): React.ReactElement | null {
  const visible = useCookiesModalVisible();
  const { toggle } = useUseCookiesModal();

  useBodyOverflow();

  if (!visible) return null;
  return (
    <div className="cookie-modal-overlay">
      <div className="cookie-modal">
        <h3>Erweiterte Cookie-Einstellungen</h3>
        <CookieItemFunctional />
        <CookieItemGoogleMaps />
        <CookieItemGoogleReCaptcha />
        <CookieItemAnalytics />
        <div className="d-flex justify-content-end">
          <button className="btn-dark" type="button" onClick={toggle}>
            speichern
          </button>
        </div>
      </div>
    </div>
  );
}
// export function CookieConsentBanner(): React.ReactElement | null {
//   const [value, setValue] = useState(getCookieConsentValue(COOKIE));
//   const onClick = useCallback(() => {
//     Cookies.set(COOKIE, 'true');
//     setValue('true');
//   }, []);
//   const responsive = useResponsive();
//   if (value === 'true') return null;
//   return (
//     <CookieConsent
//       location="bottom"
//       visible={COOKIE}
//       containerClasses="cc-container"
//       buttonClasses="cookie-btn-hidden"
//       cookieName={COOKIE}
//       overlay={responsive.large}
//     >
//       {value}
//       <p>
//         Hinweis zur Verwendung von Cookies: Um unsere Webseite für Sie optimal
//         zu gestalten und fortlaufend verbessern zu können, verwenden wir
//         Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der
//         Verwendung von Cookies zu. Sie können Cookies jederzeit in den
//         Browsereinstellungen deaktivieren.
//       </p>
//       <button type="button" className="btn cookie-btn" onClick={onClick}>
//         Alle Cookies akzeptieren
//       </button>
//       <button type="button" className="btn cookie-btn" onClick={onClick}>
//         Nur notwendige Cookies akzeptieren
//       </button>
//       <br /> <br />
//       <a href=" " data-toggle="modal" data-target="#DataPrivacy">
//         Datenschutz
//       </a>
//       <span> </span>
//       <a href=" " data-toggle="modal" data-target="#ImprintModal">
//         Impressum
//       </a>
//     </CookieConsent>
//   );
// }
