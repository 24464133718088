/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import RetailerDetails from '../RetailerDetails';

export default function Details({ model, selected }) {
  if (!selected) {
    return null;
  }

  return (
    <div className="details bg-dark text-light p-3">
      <RetailerDetails model={model} />
    </div>
  );
}

Details.propTypes = {
  model: PropTypes.object,
};
