/* eslint-disable */
// @ts-nocheck
import defaultTo from 'lodash/fp/defaultTo';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';

import closeIcon from '../../img/close.png';
import { loadDemonstrationForTrader } from '../../modules/demonstration-devices';
import { detail } from '../../modules/trader-search';
import { classes } from '../../util/cssClasses';
import { createRouteLink } from '../../util/maps';
import { getRetailerWebsite } from '../../util/website';
import Address from '../Address';
import ContactForm from '../ContactForm';
import DemonstrationDeviceList from '../DemonstrationDeviceList';
import TabArea from '../TabArea';
import Tab from '../TabArea/Tab';
import Details from './Details';
import OpeningHours from './OpeningHours';
import {useGoogleReCaptchaCookies} from "../CookieConsent/cookies";

function ContactTab({ recipient, ...rest }) {
  if (
    recipient.details.sales == null ||
    recipient.details.sales.email == null
  ) {
    return null;
  }

  return (
    <Tab
      {...rest}
      title={() => (
        <FormattedMessage id="details.contactTitle" defaultMessage="Contact" />
      )}
    >
      <ContactForm recipient={recipient} />
    </Tab>
  );
}

function DemonstrationDevicesTab({ devices, ...rest }) {
  if (devices == null || devices.length === 0) {
    return null;
  }

  return (
    <Tab
      {...rest}
      title={() => (
        <FormattedMessage
          id="details.demonstrationDeviceList"
          defaultMessage="Demonstration"
        />
      )}
    >
      <div className="pb-3">
        <FormattedMessage id="demonstrationDeviceList.preface" />
      </div>
      <DemonstrationDeviceList devices={devices} />
      <div className="pt-3">
        <FormattedMessage id="demonstrationDeviceList.epilogue" />
      </div>
    </Tab>
  );
}

export function RetailerDetails({
  model,
  close,

  sourceAddress,
  devices,
  tab,
  tabChange,
}) {
  const { value } = useGoogleReCaptchaCookies()
  return (
    <div className="RetailerDetails no-gutters">
      <div className="row align-items-start">
        <h5 className="col mb-3">{model.address.company}</h5>
        <button className="btn p-0 bg-none" onClick={close}>
          <img src={closeIcon} alt="Close" />
        </button>
      </div>

      <Address address={model.address} />

      <Details details={model.details} homePage={getRetailerWebsite(model)} />

      <OpeningHours details={model.details} />

      <div
        className={classes('row flex-row-reverse', {
          'd-none': model.address == null,
        })}
      >
        <a
          className="btn btn-go"
          target="_blank"
          href={createRouteLink(sourceAddress, model.address)}
          rel="noreferrer"
        >
          <FormattedMessage id="details.plan" defaultMessage="Plan Route" />
        </a>
      </div>

      <TabArea className="mt-3" onChange={tabChange} selected={tab}>
        <DemonstrationDevicesTab key="demonstration" devices={devices} />
        {value ? <ContactTab key="contact" recipient={model}/> : <></>}
      </TabArea>
    </div>
  );
}

RetailerDetails.propTypes = {
  model: PropTypes.shape({
    address: PropTypes.shape({
      company: PropTypes.string,
    }),
    details: PropTypes.object,
  }),
  close: PropTypes.func.isRequired,
  sourceAddress: PropTypes.string.isRequired,
};

export default compose(
  connect(
    (state, ownProps) => {
      const { lat, lng } = state.traderSearch.query;
      const { traderDemoDevices, loading } = state.demonstrationDevices;

      return {
        loading,
        locale: state.locale.locale,
        sourceAddress: `${lat}, ${lng}`,
        devices: defaultTo([], traderDemoDevices[ownProps.model.orgunitID]),
      };
    },
    dispatch => ({
      close: () => dispatch(detail(null)),
      getDemoDevices: (language, trader) =>
        dispatch(loadDemonstrationForTrader(trader, language)),
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      getDemoDevices: () =>
        dispatchProps.getDemoDevices(
          stateProps.locale,
          ownProps.model.orgunitID,
        ),
    }),
    {
      pure: false,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.getDemoDevices();
    },
  }),
  withState('tab', 'tabChange', null),
)(RetailerDetails);
