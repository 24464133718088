/* eslint-disable */
// @ts-nocheck
import './style.scss';

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import {CookieBanner2023, CookieConsent2023} from '../CookieConsent';
import GoogleMapContext from '../GoogleMapContext';
import TraderSearch from '../TraderSearch';
import TraderSearchResultsMap from '../TraderSearchMap';
import {useGoogleMapsCookies} from "../CookieConsent/cookies";

export function Warranty() {
  return (
    <div className="bg-gradient">
      <div className="mx-auto p-3 pt-4 container">
        <FormattedHTMLMessage
          id="warranty"
          defaultMessage="<h5>Warranty Information</h5>"
        />
      </div>
    </div>
  );
}

export default function Home() {
  // The padding of Home-content and the inner column has the
  // X and Y axes separated so mouse users that leave their mouse
  // over the column, scroll down and accidentally land the mouse
  // over the underlying map won't suddenly cause the map to zoom
  // instead of continuing scroll.
  const { value } = useGoogleMapsCookies();
  return (
    <>
      <CookieBanner2023 />
      <CookieConsent2023 />
      <GoogleMapContext>
        <div className="layered">
          {/*<CookieConsentBanner />*/}
          {value && <TraderSearchResultsMap/>}
          <div className="mx-auto px-3 row container no-gutters Home-content">
            <div className="col-sm-12 col-lg-6 py-3">
              <TraderSearch/>
            </div>
          </div>
        </div>
        <Warranty/>
      </GoogleMapContext>
      </>
  );
}
