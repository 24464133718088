/* eslint-disable */
// @ts-nocheck
import './style.scss';

import throttle from 'lodash/fp/throttle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { classes } from '../../util/cssClasses';

/**
 * An element with its height animated to fit its children.
 *
 * For a correct result, children of this element should all stack vertically,
 * or only a single children should be used. If you need multiple children, it
 * is best to wrap then within a single element as such:
 *
 *     <AnimatedHeight>
 *         <div>
 *             <Child1/>
 *             <Child2/>
 *             <Child3/>
 *         </div>
 *     </AnimatedHeight>
 *
 * If the children are purely logical, however (doesn't emit any DOM nodes), it
 * is perfectly fine to omit the wrapper.
 * */
export default class AnimatedHeight extends Component {
  static propTypes = {
    children: PropTypes.node,
    changed: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.wrapper = null;

    this.resizeListener = throttle(200, this.recalculateHeight.bind(this));
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  componentDidUpdate() {
    this.recalculateHeight();
  }

  render() {
    const { className, children, changed: _, ...props } = this.props;
    return (
      <div
        className={classes('AnimatedHeight', className)}
        {...props}
        ref={wrapper => (this.wrapper = wrapper)}
      >
        {children}
      </div>
    );
  }

  recalculateHeight() {
    let contentHeight = 0;

    for (const child of this.wrapper.children) {
      contentHeight += child.scrollHeight;
    }

    const pixelHeight = `${contentHeight}px`;

    const oldHeight = this.wrapper.style.maxHeight;
    this.wrapper.style.maxHeight = pixelHeight;
    this.wrapper.style.minHeight = pixelHeight;

    if (this.props.changed && pixelHeight !== oldHeight) {
      this.props.changed(contentHeight);
    }
  }
}
