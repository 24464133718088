/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';

import { classes } from '../../util/cssClasses';

/**
 * The default page button for `Paginator`.
 * */
export default function SimplePageButton({ page, click, current }) {
  return (
    <button
      className={classes('px-1 py-0 btn text-danger bg-none', {
        underline: current,
      })}
      onClick={() => {
        if (!current) {
          click();
        }
      }}
    >
      <FormattedNumber value={page + 1} />
    </button>
  );
}

SimplePageButton.propTypes = {
  page: PropTypes.number.isRequired,
  clicK: PropTypes.func,
  current: PropTypes.bool.isRequired,
};
