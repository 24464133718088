/* eslint-disable */
// @ts-nocheck
import './style.scss';

import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber } from 'react-intl';

import { classes } from '../../util/cssClasses';

/**
 * A horizontal slider for an integer interval.
 * */
export default class Slider extends Component {
  static propTypes = {
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.number,
    valueChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };

    this.thumb = null;

    this.releaseHandler = ev => {
      if (ev.type === 'mouseup' && document.activeElement !== this.thumb) {
        return;
      }

      this.setState({
        dragging: false,
      });
    };

    this.keyHandler = ev => {
      if (document.activeElement !== this.thumb) {
        return;
      }

      let direction = null;

      switch (ev.key) {
        case 'ArrowLeft':
          direction = -1;
          break;

        case 'ArrowRight':
          direction = 1;
          break;

        default:
          return;
      }

      const { start, end, step = 0, value, valueChange } = this.props;

      if (value == null && valueChange == null) {
        return;
      }

      const newValue = value + step * direction;

      const clamped = Math.max(start, Math.min(end, newValue));

      valueChange(clamped);
    };

    this.moveHandler = ev => {
      if (!this.state.dragging) {
        return;
      }

      const pointerX =
        ev.touches != null && ev.touches.length > 0
          ? ev.touches[0].pageX
          : ev.pageX;
      const trackX = this.thumb.offsetParent.getBoundingClientRect().left;
      const position = pointerX - trackX;

      const width = this.thumb.offsetParent.offsetWidth;
      const { start, end, step, value, valueChange = noop } = this.props;
      // Mouse events
      const tentative = (position / width) * (end - start) + start;
      const stepped =
        step != null ? step * Math.round(tentative / step) : tentative;
      const clamped = Math.max(start, Math.min(end, stepped));

      if (
        clamped !== value

        // Touch events
      ) {
        valueChange(clamped);
      }
    };
  }

  // Keyboard events
  componentDidMount() {
    window.document.addEventListener('mouseup', this.releaseHandler, {
      passive: true,
    });

    window.document.addEventListener('mousemove', this.moveHandler, {
      // Mouse events
      passive: true,
    });

    // Touch events
    window.document.addEventListener('touchend', this.releaseHandler, {
      passive: true,

      // Keyboard events
    });
    window.document.addEventListener('touchmove', this.moveHandler, {
      passive: true,
    });
    window.document.addEventListener('keydown', this.keyHandler, {
      passive: true,
    });
  } // Required so React doesn't complain.

  componentWillUnmount() {
    window.document.removeEventListener('mouseup', this.releaseHandler);

    window.document.removeEventListener('mousemove', this.moveHandler);
    window.document.removeEventListener('touchend', this.releaseHandler);
    window.document.removeEventListener('touchmove', this.moveHandler);

    window.document.removeEventListener('keydown', this.keyHandler);
  }

  render() {
    const {
      start,
      end,
      value,
      valueChange: _valueChange,
      className,
      ...props
    } = this.props;
    const { dragging } = this.state;
    return (
      <div className={classes('Slider', className)} {...props}>
        <div className="track" />
        <button
          type="button"
          className={classes('thumb', {
            active: dragging,
          })}
          style={{
            left: `${((value - start) / (end - start)) * 100}%`,
          }}
          onMouseDown={() =>
            this.setState({
              dragging: true,
            })
          }
          onTouchStart={ev => {
            ev.preventDefault();
            this.setState({
              dragging: true,
            });
          }}
          ref={el => (this.thumb = el)}
        >
          <FormattedNumber value={value} />
        </button>
      </div>
    );
  }
}
