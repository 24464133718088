/* eslint-disable */
// @ts-nocheck
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import SimplePageButton from './SimplePageButton';

/**
 * A paginator with custom pager item support.
 * */
export default function Paginator({
  page,
  count,
  pageSize,
  pageChange,
  renderItem = SimplePageButton,
}) {
  const pages = range(0, count, pageSize);

  const createPageButton = start => {
    const buttonPage = start / pageSize;

    const raw = renderItem({
      page: buttonPage,

      click() {
        if (pageChange != null) {
          pageChange(buttonPage);
        }
      },

      current: buttonPage === page,
    });
    return React.cloneElement(raw, {
      key: buttonPage,
    });
  };

  return <>{pages.map(createPageButton)}</>;
}
Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  pageChange: PropTypes.func,

  renderItem: PropTypes.func,
};
