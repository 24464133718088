/* eslint-disable */
// @ts-nocheck
import './styles.scss';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { classes } from '../../util/cssClasses';
import Spinner from '../Spinner/index';

function BlockingSpinner({ loading, children }) {
  return (
    <>
      <div
        className={classes('BlockingSpinner', {
          loading,
        })}
      >
        {loading}
        <Spinner spinning={loading} className="light" />
      </div>
      <div className="BlockingSpinner-blur">{children}</div>
    </>
  );
}

export default connect(state => ({
  loading: state.locale.loading || state.location.loading,
}))(BlockingSpinner);
