/* eslint-disable */
// @ts-nocheck
import {
  LatLng,
  latLng,
  LatLngBounds,
  LatLngExpression,
  Map as LeafletMap,
  PointExpression,
} from 'leaflet';
import at from 'lodash/fp/at';
import chunk from 'lodash/fp/chunk';
import cloneDeep from 'lodash/fp/cloneDeep';
import flatMap from 'lodash/fp/flatMap';
import get from 'lodash/fp/get';
import identity from 'lodash/fp/identity';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import merge from 'lodash/fp/merge';
import partial from 'lodash/fp/partial';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import reject from 'lodash/fp/reject';
import trim from 'lodash/fp/trim';
import zipWith from 'lodash/fp/zipWith';
import hash from 'object-hash';
import { F, U } from 'ts-toolbelt';
import axios from 'axios';

import { getConfig } from '../contextual-config';
import { Orgunit, OrgunitAddress } from '../modules/trader-search';
import lookupAddressCache from './lookupAddressCache';
// distanceMatrix,
const MATRIX_CACHE: {
  [key: string]: number[];
} = {};

const MAX_DISTANCE_MATRIX_DIMENSION = 24;
export function createRouteLink(
  from: string,

  /**
   * https://goo.gl/6kjKZb
   * */
  to: OrgunitAddress,
): string {
  if (typeof to === 'object') {
    to = `${to.street} ${to.nr || ''}, ${to.city}`;
  }

  const url = new URL(getConfig('googleMaps.routeBase'));
  url.searchParams.append('saddr', from);

  url.searchParams.append('daddr', to);
  url.searchParams.append('ie', 'UTF8');
  return url.toString();
}
export function traderHasPosition(trader: Orgunit): boolean {
  return (
    trader.address != null &&
    trader.address.lat != null &&
    trader.address.lng != null
  );
}
export function circunscribedCircleBoundsKm(
  center: LatLngExpression,

  radius: number,
): LatLngBounds {
  return latLng(center).toBounds(radius * 2 * 1000);
}
export function offsetPositionPixels(
  position: LatLngExpression,

  offset: PointExpression,
  map: LeafletMap,
): LatLng {
  return map.unproject(map.project(position).add(offset));
}
// export async function lookupAddress(
//   address: // Mapbox Search for Address API
//   | string
//     | {
//         city: string;
//         street: string;
//         postalCode: string;
//       },
// ): Promise<{
//   lat: number;
//   lng: number;
// }> {
//   const query =
//     typeof address === 'object'
//       ? pipe(
//           at(['street', 'postalCode', 'city']),
//           reject(isEmpty),
//           join(', '),
//         )(address)
//       : address;

//   if (lookupAddressCache[trim(query)]) {
//     return lookupAddressCache[trim(query)];
//   }

//   const {
//     body: {
//       features: [result],
//     },
//   } = await nominatim.get(`/${encodeURIComponent(query)}.json`).query({
//     limit: 1,
//     country: getConfig('openStreetMap.baseCountry'),
//   });

//   if (result == null) {
//     throw new Error('Address not found');
//   }

//   const [lng, lat] = result.center;

//   return {
//     lat,
//     lng,
//   };
// }

type AddressJSON = {
  street?: U.Nullable<string>;    
  zipcode?: U.Nullable<string>;
  city?: U.Nullable<string>;
  state?: U.Nullable<string>;
  country?: U.Nullable<string>;
};

type ApiReturn = {
  success: boolean;
  latitude: U.Nullable<string>;
  longitude: U.Nullable<string>;
};

type Result = {
  lat: U.Nullable<string>;
  lng: U.Nullable<string>;
};

const BASE_PATH = 'v1/address';

// Google Search for Address API
export async function googleSearchAddress(
  address: AddressJSON,
  errorCallback?: F.Function,
): Promise<U.Nullable<Result>> {

   const query =
    typeof address === 'object'
      ? pipe(
          at(['street', 'zipcode', 'city']),
          reject(isEmpty),
          join('+'),
        )(address)
      : address;

  const coordsFromLocalCache = lookupAddressCache[trim(query)];

  if (coordsFromLocalCache) {    
    return coordsFromLocalCache;
  }

  try {    
    const result: ApiReturn = await axios.post(`${getConfig('apiUrl')}/${BASE_PATH}/calculate-coordinates`, address);
    if (isEmpty(result.data) || result?.data?.success == false) {
      const zeroResultsError = new Error('Die angegebene Adresse konnte nicht gefunden werden.');
      if (errorCallback) {
        errorCallback(zeroResultsError);
      }      
    }
    return {
      lat: result?.data?.latitude,
      lng: result?.data?.longitude
    };        
  } catch (error) {
    if (errorCallback) {
      errorCallback(error);
    }
    throw error
  }
  // const query =
  //   typeof address === 'object'
  //     ? pipe(
  //         at(['street', 'postalCode', 'city']),
  //         reject(isEmpty),
  //         join('+'),
  //       )(address)
  //     : address;

  // if (lookupAddressCache[trim(query)]) {
  //   return lookupAddressCache[trim(query)];
  // }

  // const { key, initialLatitude, initialLongitude } = getConfig('googleMaps');
  // const {
  //   body: {
  //     candidates: [
  //       {
  //         geometry: { location },
  //       },
  //     ],
  //   },
  // } = await googleSearch.get(`/json`).query({
  //   key,
  //   input: query,

  //   locationbias: `circle:450000@${initialLatitude},${initialLongitude}`,
  // });
  // const {
  //   lng,

  //   lat,
  // } = location;
}

async function getDistanceMatrixFromCache(
  location: {
    lat: number;
    lng: number;
  },
  destinations: {
    lat: number;
    lng: number;
  }[],
): Promise<number[]> {
  const cacheKey = hash({
    location,
    destinations,

    // Mapbox Distance Matrix API
    // async function getDistanceMatrix(
    //   location: {lat: number, lng: number},
    //   destinations: {lat: number, lng: number}[],
    // ): Promise<number[]> {
    //   const cacheKey = hash({ location, destinations });
    //   const cachedResult = await getDistanceMatrixFromCache(location, destinations);
    //   if (cachedResult){
    //     return cachedResult;
    //   }
    //   const {
    //     body: {
    //       distances: [distancesFromFirst],
    //     },
    //   } = await distanceMatrix
    //     .get(`/${pipe(
    //       map(({lat, lng}) => `${lng},${lat}`),
    //       join(";"),
    //     )([location, ...destinations])}`)
    //     .query({
    //       annotations: "distance",
    //     });
    //   // Remove first item, which is distance
    //   // from source to source, and is always 0.
    //   distancesFromFirst.shift();
    //   MATRIX_CACHE[cacheKey] = distancesFromFirst;
    //   return distancesFromFirst;
    // }

    // Google Distance Matrix API
  });
  return new Promise(resolve => resolve(MATRIX_CACHE[cacheKey]));
}

const getGoogleDistanceMatrix =
  service =>
  async (
    location: {
      lat: number;
      lng: number;
    },
    destinations: {
      lat: number;
      lng: number;
    }[],
  ): Promise<number[]> => {
    const cacheKey = hash({
      location,
      destinations,
    });
    const cachedResult = await getDistanceMatrixFromCache(
      location,
      destinations,
    );

    // URL Call
    // const key = getConfig("googleMaps.key");
    // const {
    //   body: {rows: [{elements: [{distance: {value}}]}]},
    // } = await googleDistanceMatrix
    //   .get("/json")
    //   .query({
    //     key,
    //     origins: `${location.lat},${location.lng}`,
    //     destinations: destinations.map(
    //       ({lat,lng}) => `${lat},${lng}`,
    //     ).join("|"),
    //   });

    // Browser Call
    if (cachedResult) {
      return cachedResult;
    }

    const value = await new Promise(resolve =>
      service.getDistanceMatrix(
        {
          origins: [location],
          destinations,
          travelMode: 'DRIVING',
        },
        ({
          rows: [
            {
              elements: [
                {
                  distance: { value },
                },
              ],
            },
          ],
        }) => resolve(value),
      ),
    );
    MATRIX_CACHE[cacheKey] = value;
    return value;
  };

export async function fetchAccurateDistances(
  service,

  location: {
    lat: number;
    lng: number; // m to km
  },
  traders: Orgunit[],
): Promise<Orgunit[]> {
  const chunks = await pipe(
    map(get('address')),
    map(pick(['lat', 'lng'])),
    chunk(MAX_DISTANCE_MATRIX_DIMENSION),
    map(partial(getGoogleDistanceMatrix(service), [location])),
    Promise.all.bind(Promise),
  )(traders);
  return pipe(
    flatMap(identity),
    map(d => (d == null ? null : d / 1000)),
    map(routeLength => ({
      address: {
        routeLength,
      },
    })),
    zipWith(merge, cloneDeep(traders)),
  )(chunks);
}
