/* eslint-disable */
// @ts-nocheck
import './style.scss';

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { changeMainHeight } from '../../modules/layout';
import {
  reset,
  updatePersistence,
  updateQuery,
} from '../../modules/trader-search';
import { classes } from '../../util/cssClasses';
import AnimatedHeight from '../AnimatedHeight';
import RoutedSearch from '../RoutedSearch';
import SearchContent from './SearchContent';
import SearchResults from './SearchResults';

export class TraderSearch extends Component {
  static propTypes = {
    query: PropTypes.object.isRequired,
    queryChange: PropTypes.func.isRequired,
    persist: PropTypes.bool.isRequired,
    persistChange: PropTypes.func.isRequired,
    heightChange: PropTypes.func,
    clear: PropTypes.func,
    results: PropTypes.array,
  };

  componentDidUpdate({ results: prevResults }) {
    const { results } = this.props;

    if (prevResults !== results && results != null) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      query,
      queryChange,
      persist,
      persistChange,
      heightChange,
      clear,
      results,
    } = this.props;

    const hasResults = results != null;

    return (
      <>
        <div
          tabIndex={0}
          className={classes('TraderSearch bg-dark text-light', {
            clickable: hasResults,
          })}
          onClick={() => {
            if (hasResults) {
              clear();
            }
          }}
        >
          <AnimatedHeight changed={height => heightChange(height)}>
            <SearchContent
              query={query}
              queryChange={queryChange}
              persist={persist}
              persistChange={persistChange}
              results={results}
            />
          </AnimatedHeight>
        </div>
        <RoutedSearch />
        <SearchResults items={results} close={clear} radius={query.radius} />
      </>
    );
  }
}

export default connect(
  state => ({
    query: state.traderSearch.query,
    persist: state.traderSearch.persist,
    results: state.traderSearch.results,
  }),
  dispatch => ({
    queryChange: query => dispatch(updateQuery(query)),
    persistChange: persist => dispatch(updatePersistence(persist)),
    heightChange: height => dispatch(changeMainHeight(height)),
    clear: () => dispatch(reset()),
  }),
  null,
  // Needed otherwise Redux will not re-render the component when the locale is
  // changed, since this component does not directly depend on the locale.
  {
    pure: false,
  },
)(TraderSearch);
