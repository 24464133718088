/* eslint-disable */
// @ts-nocheck
import isFunction from 'lodash/fp/isFunction';

/**
 * Allows enhanced thunks to await a certain state
 * condition to be met before beginning execution.
 *
 * Any action that is a function and has a `blockUntil`
 * property will be decorated. The block function is
 * called on every state change with the current state,
 * and must return a boolean. The thunk will be queued
 * for execution until the helper function returns `true`.
 */
export const asyncDependencyMiddleware = store => {
  const queued = new Map();

  const updateDependencies = () => {
    const state = store.getState();

    for (const [blockUntil, resolve] of queued.entries()) {
      if (blockUntil(state)) {
        resolve();
        queued.delete(blockUntil);
      }
    }
  };

  return next => action => {
    updateDependencies();
    setTimeout(updateDependencies);

    // Don't do anything with non-decorated thunks or non-thunks
    if (!isFunction(action) || !isFunction(action.blockUntil)) {
      return next(action);
    }

    return next(async (...args) => {
      await new Promise(resolve => {
        const { blockUntil } = action;

        if (!blockUntil(store.getState())) {
          queued.set(action.blockUntil, resolve);
        } else {
          resolve();
        }
      });

      return await action(...args);
    });
  };
};
