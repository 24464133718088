/* eslint-disable */
// @ts-nocheck
import isNil from 'lodash/fp/isNil';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import reject from 'lodash/fp/reject';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

/**
 * Displays a labeled distance, expected unit is kilometers
 * */
export default function Distance({ value, labelSpecifier }) {
  if (value == null) {
    return null;
  }

  const id = pipe(reject(isNil), join('.'))([labelSpecifier, 'distance']);
  return (
    <FormattedMessage
      id={id}
      defaultMessage="Distance: {distance}km"
      values={{
        distance: <FormattedNumber value={value} maximumFractionDigits={1} />,
      }}
    />
  );
}

Distance.propTypes = {
  value: PropTypes.number,
  labelSpecifier: PropTypes.string,
};
