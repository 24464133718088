/* eslint-disable */
// @ts-nocheck
import identity from 'lodash/identity';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { classes } from '../../util/cssClasses';
import Slider from '../Slider';

/**
 * A responsive discrete numeric selector that displays as
 * a dropdown on desktop, and a slider on mobile devices.
 * */
export default class IntegerRangeSelector extends Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    valueChange: PropTypes.func,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  };

  render() {
    const {
      value,
      valueChange = noop,
      start,
      end,
      step,
      renderItem = identity,
      options,
      className,
      ...props
    } = this.props;

    return (
      <div className={classes('IntegerRangeSelector', className)} {...props}>
        <select
          className="form-control d-none d-lg-block"
          value={value}
          onChange={ev => valueChange(parseFloat(ev.target.value))}
        >
          {options.map(n => (
            <option value={n} key={n}>
              {renderItem(n)} Km
            </option>
          ))}
        </select>
        <Slider
          className="d-lg-none"
          start={start}
          end={end}
          step={step}
          value={value}
          valueChange={val => valueChange(val)}
        />
      </div>
    );
  }
}
