/* eslint-disable */
// @ts-nocheck
import castArray from 'lodash/fp/castArray';
import last from 'lodash/fp/last';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

class FallbackImage extends Component {
  static propTypes = {
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    render: PropTypes.func.isRequired,
  };

  _unmounted: boolean;

  constructor(props) {
    super(props);

    this._unmounted = false;
    this.state = {
      finalSrc: last(castArray(props.src)),
    };

    this._attemptLoadSrc();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { finalSrc } = this.state;
    const { finalSrc: nextFinalSrc } = nextState;
    return finalSrc !== nextFinalSrc;
  }

  componentDidUpdate({ src: oldSrc }) {
    const { src } = this.props;

    if (src !== oldSrc) {
      this._attemptLoadSrc();
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  render() {
    const { finalSrc } = this.state;
    const { render: Render } = this.props;

    return (
      <>
        <Render src={finalSrc} />
      </>
    );
  }

  _attemptLoadSrc() {
    const { src } = this.props;

    const fallbacks = [...castArray(src)];

    const tryNext = () => {
      if (this._unmounted) {
        return;
      }

      const nextSrc = fallbacks.shift();

      if (nextSrc != null) {
        test.src = nextSrc;
      }
    };

    const test = new Image();

    test.onload = () => this._setSrc(test.src);

    test.onerror = tryNext;
    tryNext();
  }

  _setSrc(value: string) {
    if (this._unmounted) {
      return;
    }

    this.setState({
      finalSrc: value,
    });
  }
}

export default FallbackImage;
