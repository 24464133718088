/* eslint-disable */
// @ts-nocheck
import './style.scss';

import React, { Fragment } from 'react';

import { classes } from '../../util/cssClasses';

export default function Tab({ title: Title, children, onClick, selected }) {
  return (
    <>
      <button
        onClick={onClick}
        className={classes('Tab-title', {
          active: selected,
        })}
      >
        <Title />
      </button>
      {selected && <div className="Tab-body">{children}</div>}
    </>
  );
}
