/* eslint-disable */
// @ts-nocheck
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../util/cssClasses';

/**
 * Utility component that returns a form row with a labeled input.
 * */
export default function FormField({
  value,
  valueChange,
  label,
  name,
  required,
  fieldProps: { className: fieldClassName, ...fieldProps } = {},
}) {
  const id = uniqueId(name);

  // TODO: Add translations for label

  return (
    <div className="form-group row">
      <label className="col-sm-4 col-xs-12 col-form-label" htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      <input
        className={classes('form-control col-sm-8 col-xs-12', fieldClassName)}
        {...fieldProps}
        id={id}
        name={name}
        value={value}
        required={required}
        onChange={ev => valueChange(ev.target.value)}
      />
    </div>
  );
}

FormField.propTypes = {
  value: PropTypes.any,
  valueChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
};
