/* eslint-disable */
// @ts-nocheck
import equals from 'lodash/fp/equals';
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { getConfig } from '../../contextual-config';
import { selectDevice } from '../../modules/demonstration-devices';
import {
  changeTrader,
  search,
  select,
  updateQuery,
} from '../../modules/trader-search';
import {
  LOCATION_SEARCH_LINK,
  searchMap,
  TRADER_SEARCH_LINK,
} from '../../util/website';

class Terminal extends Component {
  componentDidMount() {
    this._search();
  }

  componentDidUpdate({ match: { params: prevParams }, product: prevProduct }) {
    const {
      match: { params },
      product,
    } = this.props;

    if (!equals(params, prevParams) || product !== prevProduct) {
      this._search();
    }
  }

  render() {
    const { children: Children, ...rest } = this.props;

    if (Children == null) {
      return null;
    }

    return <Children {...rest} />;
  }

  async _search(): Promise<void> {
    const {
      match: {
        params: { lat, lng, radius, traderId },
      },
      product,
      search,
    } = this.props;

    const coalesceNaN = (number, or = null) => (isNaN(number) ? or : number);

    const query = {
      radius: coalesceNaN(parseInt(radius, 10), 5),
      lat: coalesceNaN(parseFloat(lat)),
      lng: coalesceNaN(parseFloat(lng)),
    };

    const trader = coalesceNaN(parseInt(traderId, 10));

    await search(query, product, trader);
  }
}

const Searcher = connect(
  state => ({
    language: state.locale.locale,
    loading: state.traderSearch.searching,
    items: state.traderSearch.results,
    locationKey: state.router.location.key,
    product: searchMap(state.router.location.search).product,
  }),
  dispatch => ({
    setTrader: id => dispatch(changeTrader(typeof id === 'number' ? id : null)),
    setProduct: erp => dispatch(selectDevice(erp)),
    select: trader => dispatch(select(trader)),

    async search(query, language, trader) {
      await dispatch(updateQuery(query));
      return await dispatch(
        search(
          query,
          language,
          trader == null
            ? null
            : {
                orgunitID: trader,
                sortOrder: getConfig('search.sortOrder'),
              },
        ),
      );
    },
  }),
  ({ language, ...stateProps }, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,

    async search(query, erp, trader) {
      await dispatchProps.setProduct(erp);
      await dispatchProps.setTrader(trader);
      const { results } = await dispatchProps.search(query, language, trader);
      await dispatchProps.select(results[0]);
    },
  }),
)(Terminal);

class RoutedSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.terminal = terminalProps => (
      <Searcher {...terminalProps}>{this.props.children}</Searcher>
    );
  }

  render() {
    return (
      <Switch>
        <Route path={LOCATION_SEARCH_LINK.path} render={this.terminal} />
        <Route path={TRADER_SEARCH_LINK.path} render={this.terminal} />
        // Needed to ensure location changes cause re-evaluation of routes
      </Switch>
    );
  }
}

export default connect(state => ({
  locationKey: state.router.location.key,
}))(RoutedSearch);
