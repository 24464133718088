/* eslint-disable */
// @ts-nocheck
import './style.scss';

import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import React from 'react';

import { TraderDemonstrationDevice } from '../../modules/demonstration-devices';
import { productImageUrl } from '../../util/request';
import FallbackImage from '../FallbackImage';

export default function DemonstrationDeviceList({
  devices,
}: {
  devices: TraderDemonstrationDevice[];
}) {
  return (
    <div className="DemonstrationDeviceList">
      {pipe(
        sortBy('name'),
        map(device => (
          <div className="DemonstrationDeviceList-item" key={device.id}>
            <FallbackImage
              src={productImageUrl(device.image)}
              render={({ src }) => (
                <div
                  className="DemonstrationDeviceList-image"
                  style={{
                    backgroundImage: `url(${src})`,
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
            />
            <h6>{device.name}</h6>
          </div>
        )),
      )(devices)}
    </div>
  );
}
