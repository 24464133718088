// import ReactGA from 'react-ga4';
import ReactGA from 'react-ga4';

import { getConfig } from '../../contextual-config/index';
import { isDevelopment } from '../environment';

function initialize() {
  const trackingID = getConfig('googleAnalytics.trackingID');
  if (!trackingID) {
    return;
  }
  ReactGA.initialize(trackingID, { testMode: isDevelopment() });
}

export default initialize;
