/* eslint-disable */
// @ts-nocheck
import isNil from 'lodash/fp/isNil';
import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../util/cssClasses';

/**
 * Displays an alert message with varying color scheme given its `type`
 * */
export default function Alert({ type, children, className, ...props }) {
  const alertType = isNil(type) ? '' : `alert-${type}`;

  return (
    <div className={classes(`alert ${alertType}`, className)} {...props}>
      {children}
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  children: PropTypes.node,
};
