/* eslint-disable */
// @ts-nocheck
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import toPairs from 'lodash/fp/toPairs';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

const utcTime = (hour, minute) =>
  new Date(Date.UTC(0, 0, 0, hour, minute, 0, 0));

const convertTimeRange = ({
  dayOfWeek,
  openingHour,
  openingMinutes,
  closingHour,
  closingMinutes,
}: {
  dayOfWeek: number;
  openingHour: number;
  openingMinutes: number;
  closingHours: number;
  closingMinutes: number;
}) => ({
  dayOfWeek,
  range: {
    start: utcTime(openingHour, openingMinutes),
    end: utcTime(closingHour, closingMinutes),
  },
});

/**
 * Displays a table of hours grouped by day and sorted by opening times.
 * */
export default function OpeningHoursTable({ hours, ...props }) {
  const ranges = pipe(
    map(convertTimeRange),
    groupBy('dayOfWeek'),
    toPairs,
    map(([key, ranges]) => ({
      day: parseInt(key, 10),
      ranges: pipe(map('range'), sortBy('start'))(ranges),
    })),
  )(hours);

  return (
    <table {...props}>
      <tbody>
        {ranges.map(({ day, ranges }) => (
          <tr key={day}>
            <td className="pr-3">
              <FormattedMessage id={`opening.day.${day}`} />
            </td>
            {ranges.map(r => (
              <td key={r} className="px-1">
                <FormattedTime value={r.start} timeZone="UTC" />
                -
                <FormattedTime value={r.end} timeZone="UTC" />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

OpeningHoursTable.propTypes = {
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.number.isRequired,
      openingHour: PropTypes.number.isRequired,
      openingMinutes: PropTypes.number.isRequired,
      closingHour: PropTypes.number.isRequired,
      closingMinutes: PropTypes.number.isRequired,
    }),
  ),
};
