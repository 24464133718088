/* eslint-disable */
// @ts-nocheck
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getConfig } from '../../contextual-config';
import Foot from './Foot';
import Head from './Head';
import NoResults from './NoResults';

export const PAGE_SIZE = getConfig('search.uiPageSize');

export default class ResultsList extends Component {
  static propTypes = {
    close: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  render() {
    const { close = noop, children } = this.props;
    const { page } = this.state;

    const start = page * PAGE_SIZE;

    const content =
      children.length === 0 ? (
        <NoResults close={close} />
      ) : (
        children.slice(start, start + PAGE_SIZE)
      );

    return (
      <div className="mt-3 bg-gradient no-gutters">
        <Head close={close} />
        <div className="row d-flex flex-column">{content}</div>
        <Foot
          page={page}
          count={children.length}
          change={page =>
            this.setState({
              page,
            })
          }
        />
      </div>
    );
  }
}
