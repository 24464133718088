/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

/**
 * Displays an address separated in two parts,
 * one for local, another for region.
 *
 * If no address is given, displays nothing.
 * */
export default function Address({ address }) {
  if (address == null) {
    return null;
  }

  return (
    <>
      <div>
        {address.street} {address.nr}
      </div>
      <div>
        {address.zipcode} {address.city}
      </div>
    </>
  );
}

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    nr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zipcode: PropTypes.string,
    city: PropTypes.string,
  }),
};
