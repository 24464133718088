/* eslint-disable */
// @ts-nocheck
import { InfoWindow, Marker } from '@react-google-maps/api';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/map';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AddressDistance from '../Distance/AddressDistance';
import useGoogleMapContext from '../GoogleMapContext/useGoogleMapContext';

function Popup({ trader }) {
  const { services } = useGoogleMapContext();
  const { distance } = services;
  return (
    <div className="no-gutters">
      <div className="row font-weight-bold">{trader?.address?.company}</div>
      <div className="row">
        {trader?.address?.street} {trader?.address?.nr}
      </div>
      <div className="row">
        {trader?.address?.zipcode} {trader?.address?.city}
      </div>
      <div className="row">
        <AddressDistance trader={trader} service={distance} />
      </div>
      {!isEmpty(trader?.details?.sales?.email) && (
        <a className="row text-dark" href={`mailto:${trader?.details?.sales?.email}`}>
          <FormattedMessage id="details.contact" defaultMessage="Contact" />
        </a>
      )}
    </div>
  );
}

const infoWindowOptions = {
  pixelOffset: {
    width: 0,
    height: -45,
  },
};

function TraderMarkers({ traders, select, selected }) {
  if (!traders) return null;
  return map(traders, trader => {
    const position = {
      lat: trader?.address?.lat,
      lng: trader?.address?.lng,
    };

    const isSelected = trader?.orgunitID === selected?.orgunitID;
    return (
      <Marker
        key={`${position?.lat}, ${position?.lng}`}
        position={position}
        onClick={() => select(trader)}
      >
        {isSelected && (
          <InfoWindow position={position} options={infoWindowOptions}>
            <Popup trader={trader} />
          </InfoWindow>
        )}
      </Marker>
    );
  });
}

export default TraderMarkers;
