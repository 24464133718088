/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import closeIcon from '../../img/close_black.png';

export default function Head({ close }) {
  return (
    <div className="row">
      <h5 className="m-3 col">
        <FormattedMessage id="results.title" defaultMessage="Nearby results" />
      </h5>
      <button className="m-3 btn p-0 bg-none" onClick={close}>
        <img src={closeIcon} alt="Close" />
      </button>
    </div>
  );
}

Head.propTypes = {
  close: PropTypes.func.isRequired,
};
