/* eslint-disable */
// @ts-nocheck
import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../util/cssClasses';

export default function Spinner({ spinning, className }) {
  return (
    <span
      className={classes(
        'Spinner',
        {
          spinning,
        },
        className,
      )}
    />
  );
}

Spinner.propTypes = {
  spinning: PropTypes.bool,
};
