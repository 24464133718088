/* eslint-disable */
// @ts-nocheck
import { join } from 'path';
import { compile, PathFunction } from 'path-to-regexp';

import { Orgunit } from '../modules/trader-search';

type DetailsKind = keyof Orgunit['details'];

export type RouteUrl = {
  path: string;
  fill: PathFunction;
};

const makeRoute = (path: string): RouteUrl => {
  const fill: RouteUrl = compile(path);
  return {
    path,
    fill,
  };
};

export const LOCATION_SEARCH_LINK: RouteUrl = makeRoute(
  '/within/:radius/of/:lat,:lng',
);
export const TRADER_SEARCH_LINK: RouteUrl = makeRoute('/trader/:traderId');

export function getRetailerWebsite(
  model: Orgunit,
  type: DetailsKind = 'sales',
): string {
  return model.details[type] == null
    ? model.homePage
    : model.details[type].homepage;
}

export function ensureSchemaLink(link: string) {
  // Link must have a dot to be considered valid.
  // "potato.com" is valid, but "potato" is not.
  //
  // Additionally, it must not be just a sequence
  // of dots, and have some content at either side.
  if (link == null || !/[^.]\.[^.]/.test(link)) {
    return null;
  }

  if (/^https?:/.test(link)) {
    return link;
  }

  return `http://${link}`;
}

export function mergeSearch(search: string, changes: object): string {
  const pseudo = new URL('http://a.com');
  pseudo.search = search;

  for (const key in changes) {
    if (!changes.hasOwnProperty(key)) {
      continue;
    }

    const value = changes[key];

    if (value != null) {
      pseudo.searchParams.set(key, value);
    } else {
      pseudo.searchParams.delete(key);
    }
  }

  return pseudo.search;
}

export function searchMap(search: string): object {
  const pseudo = new URL('http://a.com');
  pseudo.search = search;

  const result = {};

  for (const [key, value] of pseudo.searchParams.entries()) {
    result[key] = value;
  }

  return result;
}

export function joinUrlPath(link: string, path: string): string {
  const url = new URL(link);
  url.pathname = join(url.pathname, path);
  return url.toString();
}
