/* eslint-disable */
// @ts-nocheck
import isEmpty from 'lodash/fp/isEmpty';
import prop from 'lodash/fp/prop';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getConfig } from '../../contextual-config';
import closeIcon from '../../img/close.png';
import closeCircleIcon from '../../img/close-circle.png';
import {
  filterProducts,
  selectDevice,
  setDevicesVisible,
} from '../../modules/demonstration-devices';
import { classes } from '../../util/cssClasses';
import FallbackImage from '../FallbackImage';
import InlineWindow from '../InlineWindow';
import Spinner from '../Spinner';

function ProductImage({ image }) {
  return (
    <FallbackImage
      src={image || ''}
      render={({ src }) => (
        <div
          className="aspect-square product-image"
          style={{
            backgroundImage: `url(${src})`,
          }}
        />
      )}
    />
  );
}

function DemonstrationDeviceSelection({
  devices: allDevices,
  filteredDevices,
  selected,
  select,
  closeProductView,
  filter,
  filterProducts,
  loadingFilter,
}) {
  let devices = allDevices;
  let noResults = false;

  if (filter != null && filter.length) {
    devices = filteredDevices;
    noResults = !filteredDevices.length && !loadingFilter;
  }

  devices = devices || [];
  return (
    <InlineWindow
      close={closeProductView}
      className="DemonstrationDeviceSelection"
      title={() => (
        <h6 className="my-0 flex-fill">
          <FormattedMessage
            id="search.availableDemonstrationDevices"
            defaultMessage="Devices available for demonstration"
          />
        </h6>
      )}
    >
      <div className="row">
        <input
          className={classes('col-sm-6 col-xs-6 ml-4 mb-1')}
          id="product-name"
          name="name"
          value={filter || ''}
          onChange={event => filterProducts(event.target.value, allDevices)}
          type="text"
          placeholder="Neue Suche"
        />
        <button className="btn btn-search ml-3 mb-1" type="button">
          <FormattedMessage id="search.search" defaultMessage="Search" />
        </button>
        <Spinner spinning={loadingFilter} className="dark ml-3 mb-1" />
      </div>

      {noResults && (
        <div className="row">
          <div className={classes('col-sm-12 col-xs-12 ml-4 mb-1')}>
            <FormattedMessage
              id="search.noResultsFiltered"
              defaultMessage="No results were found for your filter."
            />
          </div>
        </div>
      )}

      <div className="DemonstrationDeviceSelection-body">
        <div className="">
          {devices.map(device => (
            <button
              type="button"
              className={classes(
                'DemonstrationDeviceSelection-item no-gutters text-left',
                {
                  active: device === selected,
                },
              )}
              key={device.erpmaterialkey}
              onClick={() => select(device)}
            >
              <div className="img col-12 pb-3">
                <ProductImage image={device.image} />
              </div>
              <div className="row">
                <h5 className="col-xs-12 col-sm-12 my-0">{device.name}</h5>
                <aside className="col-xs-12 col-sm-8">
                  {device.description}
                </aside>
              </div>
            </button>
          ))}
        </div>
      </div>
    </InlineWindow>
  );
}

function DisplayProduct({
  showImage,
  visible,
  product,
  clearProduct,
  selectProduct,
  openProductView,
  closeProductView,
  devices,
  filteredDevices,
  loading,
  filter,
  filterProducts,
  loadingFilter,
}) {
  const hasTitle = !(showImage || isEmpty(prop('name', product)));
  const disableProductSelection = getConfig('features.disableProductSelection');
  const hideProductSelection = product == null && disableProductSelection;

  if (hideProductSelection) {
    return null;
  }

  return (
    <>
      <div
        className="row product-buttons-point"
        onClick={ev => {
          if (product == null && !disableProductSelection) {
            openProductView();
          }

          // eslint-disable-next-line max-len
          ev.stopPropagation();
        }}
      >
        <div className="product-buttons">
          <div className="ml-2" />
          <div className="m-0 p-2 d-flex">
            <FormattedMessage
              id="search.subtitleProduct"
              defaultMessage="Finden Sie ein bestimmtes vorführbereites Produkt."
            />
          </div>
          {hasTitle && <h4 className="m-0 pb-2">{product.name}</h4>}
          <Spinner /* {!disableProductSelection &&
            <button
              type="button"
              className="m-0 btn bg-none test"
              onClick={openProductView}
            >
            </button>
          } */
            spinning={loading}
          />
          {}
          {product != null && (
            <button
              type="button"
              className="m-0 btn bg-none close-right"
              onClick={clearProduct}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          )}
        </div>
      </div>
      {product != null && (
        <>
          {showImage && (
            <div className="row">
              <div className="col-sm-4 col-xs-12 pb-3">
                {product != null && (
                  <button
                    type="button"
                    className="m-0 btn bg-none btn-close"
                    onClick={clearProduct}
                  >
                    <img src={closeCircleIcon} alt="Close" />
                  </button>
                )}
                <ProductImage image={product.image} />
                <h5 className="mt-1 pl-0">{product.name}</h5>
              </div>
            </div>
          )}
        </>
      )}
      {visible && (
        <DemonstrationDeviceSelection
          devices={devices}
          filteredDevices={filteredDevices}
          selected={product}
          select={selectProduct}
          closeProductView={closeProductView}
          filter={filter}
          filterProducts={filterProducts}
          loadingFilter={loadingFilter}
        />
      )}
    </>
  );
}

DisplayProduct.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  close: PropTypes.func,
};
export default connect(
  state => ({
    product: state.demonstrationDevices.current,
    filter: state.demonstrationDevices.filter,
    devices: state.demonstrationDevices.devices,
    filteredDevices: state.demonstrationDevices.filteredDevices,
    visible: state.demonstrationDevices.visible,
    loading: state.demonstrationDevices.loading,
    loadingFilter: state.demonstrationDevices.loadingFilter,
  }),
  dispatch => ({
    clearProduct: () => dispatch(selectDevice(null)),
    openProductView: () => dispatch(setDevicesVisible(true)),
    closeProductView: () => dispatch(setDevicesVisible(false)),
    filterProducts: (value, devices) =>
      dispatch(filterProducts(value, devices, 'de')),

    async selectProduct(product) {
      await dispatch(selectDevice(product.erpmaterialkey));
      await dispatch(setDevicesVisible(false));
    },
  }),
  null,
  {
    pure: false,
  },
)(DisplayProduct);
