/* eslint-disable */
// @ts-nocheck
import 'babel-polyfill';
import 'url-polyfill';
import 'url-search-params-polyfill';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'leaflet/dist/leaflet.css';
import './contextual-config';
import './index.scss';

import { push } from 'connected-react-router';
import intl from 'intl';
import get from 'lodash/fp/get';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';

import App from './components/App';
import BlockingSpinner from './components/BlockingSpinner';
import {
  loadAvailableDevices,
  selectDevice,
} from './modules/demonstration-devices';
import { changeLocale, withLocaleMessage } from './modules/locale';
import { makeStore } from './util/store';
import { mergeSearch } from './util/website';

global.Intl = intl;
window.Intl = intl;

const target = document.getElementById('root');
const store = makeStore();

const IntlHandler = withLocaleMessage(IntlProvider);

ReactDOM.render(
  <ReduxProvider store={store}>
    <BlockingSpinner>
      <IntlHandler>
        <App />
      </IntlHandler>
    </BlockingSpinner>
  </ReduxProvider>,
  target,
);

(async () => {
  const queryParameters = new URL(document.location.href).searchParams;
  const device = {
    erpmaterialkey:
      queryParameters.get('demoDeviceErpMaterialKey') ||
      queryParameters.get('product'),
    description: queryParameters.get('demoDeviceDescription'),
    name: queryParameters.get('demoDeviceName'),
  };

  const { locale } = store.getState().locale;

  store.dispatch(changeLocale(locale));

  await store.dispatch(loadAvailableDevices(locale));

  if (device.erpmaterialkey != null) {
    await store.dispatch(selectDevice(device.erpmaterialkey));
  }
})();

store.subscribe(() => {
  const state = store.getState();
  const synchronizedSearch = mergeSearch(state.router.location.search, {
    product: get('erpmaterialkey', state.demonstrationDevices.current),
  });

  if (
    state.router.location.search !== synchronizedSearch &&
    !state.demonstrationDevices.loading
  ) {
    store.dispatch(
      push({
        ...state.router.location,
        search: synchronizedSearch,
      }),
    );
  }
});
