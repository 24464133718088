/* eslint-disable */
// @ts-nocheck
import { noop } from 'lodash';
import React from 'react';

import { getConfig } from '../../contextual-config';

const {
  initialLatitude,
  initialLongitude,
  initialZoom: zoom,
} = getConfig('googleMaps');

const center = {
  lat: initialLatitude,
  lng: initialLongitude,
}; // GERMANY

export const INITIAL_VALUE = {
  center,
  zoom,
  traders: null,
  selected: null,
  select: noop,
  origin: null,
  radius: 20,
  setRadius: noop,
  isLoaded: null,
  loadError: null,
  services: {
    search: null,
    distance: null,
  },
  setServices: noop,
};

export default React.createContext(INITIAL_VALUE);
