/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../util/cssClasses';

export default function ItemLink({ href, className, children, ...props }) {
  if (href == null || href.toString().length === 0) {
    return null;
  }

  return (
    <a
      {...props}
      href={href.toString()}
      target="_blank"
      className={classes(className, 'mr-1')}
    >
      {children}
    </a>
  );
}

ItemLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};
