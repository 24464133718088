/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Alert from '../Alert';

export default function Situation({ loading, success, ...props }) {
  if (loading || success == null) {
    return null;
  }

  if (success) {
    return (
      <Alert type="success" {...props}>
        <FormattedMessage
          id="contact.success"
          defaultMessage="Successfully sent message"
        />
      </Alert>
    );
  }

  return (
    <Alert type="danger" {...props}>
      <FormattedMessage
        id="contact.error"
        defaultMessage="Failed sending message"
      />
    </Alert>
  );
}
Situation.propTypes = {
  ...Alert.propTypes,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool,
};
