/* eslint-disable */
// @ts-nocheck
import debounce from 'debounce-async';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import groupBy from 'lodash/fp/groupBy';
import includes from 'lodash/fp/includes';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import propEq from 'lodash/fp/propEq';
import reduce from 'lodash/fp/reduce';
import toPairs from 'lodash/fp/toPairs';
import { Action } from 'redux';

import { getConfig } from '../contextual-config';
import { api, productImageUrl } from '../util/request';

const AVAILABLE_DEMOS_START = 'trader-search/AVAILABLE_DEMOS_START';
const AVAILABLE_DEMOS_SUCCESS = 'trader-search/AVAILABLE_DEMOS_SUCCESS';
const AVAILABLE_DEMOS_ERROR = 'trader-search/AVAILABLE_DEMOS_ERROR';

const LOAD_DEMOS_START = 'trader-search/LOAD_DEMOS_START';
const LOAD_DEMOS_SUCCESS = 'trader-search/LOAD_DEMOS_SUCCESS';
const LOAD_DEMOS_ERROR = 'trader-search/LOAD_DEMOS_ERROR';

const SELECT_DEMO_DEVICE = 'trader-search/SELECT_DEMO_DEVICE';
const ADD_DEMO_DEVICE = 'trader-search/ADD_DEMO_DEVICE';
const SET_LIST_VISIBILITY = 'trader-search/SET_LIST_VISIBILITY';

const FILTER_PRODUCTS_ENTERED = 'trader-search/FILTER_PRODUCTS_ENTERED';
const FILTER_PRODUCTS_START_LOAD = 'trader-search/FILTER_PRODUCTS_START_LOAD';
const FILTER_PRODUCTS_END_LOAD = 'trader-search/FILTER_PRODUCTS_END_LOAD';
const FILTER_PRODUCTS_SUCCESS = 'trader-search/FILTER_PRODUCTS_SUCCESS';
const FILTER_PRODUCTS_ERROR = 'trader-search/FILTER_PRODUCTS_ERROR';

export type TraderDemonstrationDevice = {
  name: string;
  ean: string;
  id?: number;
  brand?: string;
  brandLogoImage?: string;
  serialHelpText?: string;
  serialHelpImage?: string;
  couponHelptext?: string;
  couponHelpImage?: string;
  image?: string;
  kit?: boolean;
  accessory?: boolean;
  flagchecknr?: boolean;
  classOfGoodID?: number;
  serialnumber?: string;
  deliveryDate?: string;
  erpmaterialkey?: string;
};

export type DemonstrationDevice = {
  name: string;
  description: string;
  erpmaterialkey: string;
};

const emptyState: {
  devices: DemonstrationDevice[];
  filteredDevices: DemonstrationDevice[];
  current: DemonstrationDevice;
  traderDemoDevices: {
    [key: string]: TraderDemonstrationDevice[];
  };
  loading: boolean;
  visible: boolean;
  filter: string;
  loadingFilter: boolean;
  loadingCounter: number;
} = {
  devices: [],
  filteredDevices: [],
  current: null,
  traderDemoDevices: {},
  loading: false,
  visible: false,
  filter: '',
  loadingCounter: 0,
  loadingFilter: false,
};

export const reducer = (state = emptyState, action: Action) => {
  const findCurrent = (list, erp) => find(propEq('erpmaterialkey', erp), list);

  if (action.type === LOAD_DEMOS_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === LOAD_DEMOS_SUCCESS) {
    return {
      ...state,
      traderDemoDevices: {
        ...state.traderDemoDevices,
        [action.trader]: action.results,
      },
      loading: false,
    };
  }

  if (action.type === LOAD_DEMOS_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (action.type === AVAILABLE_DEMOS_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === AVAILABLE_DEMOS_SUCCESS) {
    return {
      ...state,
      devices: action.list,
      loading: false,
      current: findCurrent(action.list, state._current),
    };
  }

  if (action.type === AVAILABLE_DEMOS_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (action.type === ADD_DEMO_DEVICE) {
    return {
      ...state,
      devices: [...state.devices, action.device],
    };
  }

  if (action.type === SELECT_DEMO_DEVICE) {
    return {
      ...state,
      _current: action.erp,
      current: findCurrent(state.devices, action.erp),
    };
  }

  if (action.type === SET_LIST_VISIBILITY) {
    return {
      ...state,
      visible: action.visible,
      filter: '',
      filteredDevices: [],
    };
  }

  if (action.type === FILTER_PRODUCTS_ENTERED) {
    return {
      ...state,
      filter: action.filter,
    };
  }

  if (action.type === FILTER_PRODUCTS_START_LOAD) {
    const newLoadingCounter = state.loadingCounter + 1;
    return {
      ...state,
      loadingCounter: newLoadingCounter + 1,
      loadingFilter: newLoadingCounter !== 0,
    };
  }

  if (action.type === FILTER_PRODUCTS_END_LOAD) {
    const newLoadingCounter = state.loadingCounter + 1;
    return {
      ...state,
      loadingCounter: newLoadingCounter - 1,
      loadingFilter: newLoadingCounter !== 0,
    };
  }

  if (action.type === FILTER_PRODUCTS_SUCCESS) {
    return {
      ...state,
      filteredDevices: action.filteredDevices,
      loadingFilter: false,
    };
  }

  if (action.type === FILTER_PRODUCTS_ERROR) {
    return {
      ...state,
      filteredDevices: [],
      loadingFilter: false,
    };
  }

  return state;
};

export const selectDevice = (erp: string) => async dispatch => {
  dispatch({
    type: SELECT_DEMO_DEVICE,
    erp,
  });
};

export const setDevicesVisible = (visible: boolean) => async dispatch => {
  dispatch({
    type: SET_LIST_VISIBILITY,
    visible,
  });
};

const findProductsByRequest = debounce(
  async (
    inputFilter: string,
    devices: DemonstrationDevice[],
    language: string,
    dispatch,
  ) => {
    const { body: results } = await api.get(`/v1/consumer/product`, {
      language,
      salesOrgBrand: getConfig('salesOrgBrand'),
      name: inputFilter,
      limit: 100,
      offset: 0,
      actor: 1, // XXX: trader as actor to find products added by traders
      likeNameOrMaterialKey: true,
    });

    let filteredDevices = [];

    if (results.total > 0) {
      const foundProducts = groupProductsByClassOfGoods(results.list);
      const whitelist = map('name', foundProducts);
      filteredDevices = pipe(
        filter(
          product => whitelist == null || includes(product.name, whitelist),
        ),
      )(devices);
    }

    dispatch({
      type: FILTER_PRODUCTS_END_LOAD,
    });

    return filteredDevices;
  },
  500,
);

export const filterProducts =
  (inputFilter: string, devices: DemonstrationDevice[], language: string) =>
  async dispatch => {
    dispatch({
      type: FILTER_PRODUCTS_ENTERED,
      filter: inputFilter,
    });

    if (inputFilter == null || !inputFilter.length) {
      return dispatch({
        type: FILTER_PRODUCTS_SUCCESS,
        filteredDevices: [],
      });
    }

    try {
      dispatch({
        type: FILTER_PRODUCTS_START_LOAD,
        filter: inputFilter,
      });
      const filteredDevices = await findProductsByRequest(
        inputFilter,
        devices,
        language,
        dispatch,
      );
      return dispatch({
        type: FILTER_PRODUCTS_SUCCESS,
        filteredDevices: filteredDevices || [],
      });
    } catch (error) {
      if (error === 'canceled') {
        return;
      }

      dispatch({
        type: FILTER_PRODUCTS_END_LOAD,
      });
      throw dispatch({
        type: FILTER_PRODUCTS_ERROR,
        error,
      });
    }
  };
export const loadDemonstrationForTrader =
  (traderId: number, language: string) => async dispatch => {
    dispatch({
      type: LOAD_DEMOS_START,
    });

    try {
      const { body: results } = await api.get(
        `/v1/consumer/trader/${traderId}/demonstration-devices`,
        {
          language,

          salesOrgBrandID: getConfig('salesOrgBrand'),
        },
      );
      return dispatch({
        type: LOAD_DEMOS_SUCCESS,
        trader: traderId,
        results,
      });
    } catch (error) {
      throw dispatch({
        type: LOAD_DEMOS_ERROR,
        error,
      });
    }
  };

const groupProductsByClassOfGoods = products => {
  const { whitelist, replacements } = getConfig('demoDeviceSchema')[36];
  return pipe(
    map(product => ({
      ...product,
      classOfGoodName: reduce(
        (cog, [expr, replace]) => cog.replace(new RegExp(expr), replace),
        product.classOfGoodName || '',
        toPairs(replacements),
      ),
    })),
    filter(
      product =>
        whitelist == null || includes(product.classOfGoodName || '', whitelist),
    ),
    groupBy(prop('classOfGoodName')),
    toPairs,
    map(([cog, variants]) => ({
      name: cog,
      erpmaterialkey: pipe(map(prop('erpMaterialKey')), join(','))(variants),
      products: pipe(map(prop('name')), join(','))(variants),
      image: map(pipe(prop('image'), productImageUrl))(variants),
    })),

    orderBy(prop('name'), ['asc']),
  )(products);
};

export const loadAvailableDevices = (language: string) => async dispatch => {
  dispatch({
    type: AVAILABLE_DEMOS_START,
  });

  try {
    const { whitelist, replacements } = getConfig('demoDeviceSchema')[36];
    const { body: results } = await api.get(
      '/v1/consumer/product/available-demonstration-devices',
      {
        language,
        salesOrgBrandID: getConfig('salesOrgBrand'),
      },
    );
    const groupedGoods = groupProductsByClassOfGoods(results);
    return dispatch({
      type: AVAILABLE_DEMOS_SUCCESS,
      list: groupedGoods,
    });
  } catch (error) {
    throw dispatch({
      type: AVAILABLE_DEMOS_ERROR,
      error,
    });
  }
};
export const persistence = {
  save() {},

  load() {
    return {};
  },
};
