/* eslint-disable */
// @ts-nocheck
import { combineReducers } from 'redux';

import { reducer as contact } from './contact';
import { reducer as demonstrationDevices } from './demonstration-devices';
import { reducer as layout } from './layout';
import { reducer as locale } from './locale';
import { reducer as location } from './location';
import { reducer as traderSearch } from './trader-search';

export default combineReducers({
  traderSearch,
  locale,
  contact,
  layout,
  location,
  demonstrationDevices,
});
