/* eslint-disable */
// @ts-nocheck
export default {
  '34626': {
    lat: 50.8772781,
    lng: 9.3581742,
  },
  '33332': {
    lat: 51.897298,
    lng: 8.3916568,
  },
  '61350': {
    lat: 50.2370061,
    lng: 8.6093949,
  },
  '86899': {
    lat: 48.07307,
    lng: 10.85844,
  },
  '02826': {
    lat: 51.1483926,
    lng: 14.9710718,
  },
  '63457': {
    lat: 50.1149566,
    lng: 8.9744605,
  },
  '28870': {
    lat: 53.11703499999999,
    lng: 9.118623099999999
  },
  Gorlitz: {
    lat: 51.1483926,
    lng: 14.9710718,
  },
  Görlitz: {
    lat: 51.1483926,
    lng: 14.9710718,
  },
  gorlitz: {
    lat: 51.1483926,
    lng: 14.9710718,
  },
  görlitz: {
    lat: 51.1483926,
    lng: 14.9710718,
  },
  Regen: {
    lat: 48.972694,
    lng: 13.127583,
  },
  regen: {
    lat: 48.972694,
    lng: 13.127583,
  },
  Radeberg: {
    lat: 51.113013,
    lng: 13.919369,
  },
  radeberg: {
    lat: 51.113013,
    lng: 13.919369,
  },
};
