/* eslint-disable */
// @ts-nocheck
import fromPairs from 'lodash/fp/fromPairs';
import superagent, { SuperAgent } from 'superagent';
import prefix from 'superagent-prefix';
import use from 'superagent-use';

import { getConfig } from '../contextual-config';

const superagentScoped = uri => {
  const url = new URL(uri);
    
  return use(superagent)
    .use(prefix(`${url.origin}${url.pathname}`))
    .use(req => req.query(fromPairs([...url.searchParams.entries()])));
};

export const api: SuperAgent = superagentScoped(getConfig('apiUrl')).use(req =>
  req.set('accept', 'application/json'),
);

export const logoUrl = orgunitID =>
  `${getConfig('staticUrl')}/images/pos/${getConfig(
    'salesOrgBrand',
  )}/${orgunitID}.jpg`;

export const productImageUrl = (imageUrl: string) =>
  `${getConfig('staticUrl')}${imageUrl.replace(/^\/static/, '')}`;

export const nominatim: SuperAgent = superagentScoped(
  getConfig('openStreetMap.nominatimUrl'),
);

export const distanceMatrix: SuperAgent = superagentScoped(
  getConfig('openStreetMap.distanceMatrixUrl'),
);

export const googleSearch: SuperAgent = superagentScoped(
  getConfig('googleMaps.search'),
);

export const googleDistanceMatrix: SuperAgent = superagentScoped(
  getConfig('googleMaps.distanceMatrixUrl'),
);
