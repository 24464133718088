/* eslint-disable */
// @ts-nocheck
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function NoResults({ close = noop }) {
  return (
    <div className="p-3">
      <FormattedMessage
        id="results.empty"
        defaultMessage={
          'Unfortunately there were no results. ' +
          'Please check the provided information by opening the {link}.'
        }
        values={{
          link: (
            <button className="btn btn-link text-dark p-0" onClick={close}>
              <FormattedMessage
                id="applicationName"
                defaultMessage="Retail Locator"
              />
            </button>
          ),
        }}
      />
    </div>
  );
}

NoResults.propTypes = {
  close: PropTypes.func,
};
