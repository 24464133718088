import './styles.scss';

import { configResponsive } from 'ahooks';
import { ConnectedRouter } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import ReactGTM, { TagManagerArgs } from 'react-gtm-module';
import { Route, Switch } from 'react-router-dom';

import listenForHistory from '../../commum/ga/history';
import initialize from '../../commum/ga/initialize';
import { getConfig } from '../../contextual-config';
import { history } from '../../util/store';
import Footer from '../Footer';
import Header from '../Header';
import Home from '../Home';
import NotFound from '../NotFound';

type Return = (route: string, state?: Record<string, any>) => void;
type UseNavigateTo = () => Return;

configResponsive({
  small: 0,
  middle: 480,
  large: 1024,
});
initialize();
listenForHistory(history);

ReactGTM.initialize({
  gtmId: getConfig('googleAnalytics.trackingID'),
} as TagManagerArgs);

const useNavigateTo: UseNavigateTo = () =>
  useCallback((route, state) => {
    if (history) {
      history.push(route, { ...state, lastRoute: window.location.pathname });
    }
  }, []);

export const HandleBaseRedirect = (): null => {
  const navigateTo = useNavigateTo();
  useEffect(() => {
    if (window.location?.pathname?.includes('.html')) {
      navigateTo('/');
    }
  }, [navigateTo]);
  return null;
};

export default function App(): React.ReactElement {
  return (
    <div className="App">
      <Header />
      <HandleBaseRedirect />
      <main>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </ConnectedRouter>
      </main>
      <Footer />
    </div>
  );
}
