/* eslint-disable */
// @ts-nocheck
import pick from 'lodash/fp/pick';
import { Action } from 'redux';

const LOCATION_REQUEST_START = 'location/REQUEST_START';
const LOCATION_REQUEST_SUCCESS = 'location/REQUEST_SUCCESS';
const LOCATION_REQUEST_ERROR = 'location/REQUEST_ERROR';

const emptyState = {
  loading: false,
  error: null,
  lat: null,
  lng: null,
};

export const reducer = (state = emptyState, action: Action) => {
  if (action.type === LOCATION_REQUEST_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === LOCATION_REQUEST_SUCCESS) {
    return {
      ...state,
      loading: false,
      error: null,
      ...pick(['lat', 'lng'], action),
    };
  }

  if (action.type === LOCATION_REQUEST_ERROR) {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }

  return state;
};

export const requestCurrentLocation = () => async dispatch => {
  dispatch({
    type: LOCATION_REQUEST_START,
  });

  try {
    const { geolocation } = window.navigator;

    if (geolocation == null) {
      throw new Error('GEOLOCATION_NOT_SUPPORTED');
    }

    const {
      coords: { latitude, longitude },
    } = await new Promise(geolocation.getCurrentPosition.bind(geolocation));

    return dispatch({
      type: LOCATION_REQUEST_SUCCESS,
      lat: latitude,
      lng: longitude,
    });
  } catch (error) {
    throw dispatch({
      type: LOCATION_REQUEST_ERROR,
      error,
    });
  }
};
