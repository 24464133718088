/* eslint-disable */
// @ts-nocheck
import groupBy from 'lodash/fp/groupBy';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ResultsList from '../ResultsList';
import RetailerListItem from '../RetailerListItem';

function ExtraResultsNotice({ radius }) {
  return (
    <div className="RetailerListItem ExtraResultsNotice">
      <FormattedMessage
        id="results.extra"
        defaultMessage="Traders further than {radius}km"
        values={{
          radius,
        }}
      />
    </div>
  );
}

export default function SearchResults({ items, close, radius }) {
  if (items == null) {
    return null;
  }

  const { true: outside, false: inside } = groupBy(
    retail => retail.address.distance > radius,
    items,
  );

  const retailToListItem = retail => (
    <RetailerListItem key={retail.orgunitID} model={retail} />
  );

  const elements = reject(isNil)([
    ...map(retailToListItem, inside),
    isEmpty(outside) ? null : (
      <ExtraResultsNotice key="EXTRA" radius={radius} />
    ),
    ...map(retailToListItem, outside),
  ]);

  return <ResultsList close={close}>{elements}</ResultsList>;
}

SearchResults.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  close: PropTypes.func,
};
