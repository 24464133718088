/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import fallback from '../../img/Autorisierter_JURA_Haendler.jpg';
import { logoUrl } from '../../util/request';
import FallbackImage from '../FallbackImage';
// import fallback from "../../img/Logo_autor_Fachhandel.png";
// import authorized from "../../img/Logo_autor_Fachhandel.png";
// import premiumPartnerExcellence from "../../img/Logo_Premium_Partner_Excellence.png";
// import premiumPartnerPremium from "../../img/Logo_Premium_Partner_Premium.png";
// import premiumPartner from "../../img/Logo_Premium_Partner.png";

// const orgunitsMapped = {
//   529816: premiumPartnerExcellence,
//   700294: authorized,
//   359: premiumPartnerPremium,
//   528603: authorized,
//   529622: authorized,
//   406: premiumPartnerPremium,
//   72111: authorized,
//   386245: authorized,
//   10492: authorized,
//   878354: authorized,
// };

export default function Logo({ orgunit, className, ...rest }) {
  // const mappedLogo = orgunitsMapped[orgunit.orgunitID];
  // const logo = mappedLogo || fallback;
  const mappedLogo = logoUrl(orgunit.erpnumber);

  return (
    <FallbackImage
      src={[mappedLogo, fallback]}
      render={({ src }) => (
        <div
          className={className}
          style={{
            backgroundImage: `url(${src})`,
          }}
          {...rest}
        />
      )}
    />
  );
}

Logo.propTypes = {
  orgunit: PropTypes.shape({
    erpnumber: PropTypes.string,
  }),
  className: PropTypes.string,
};
