/* eslint-disable */
// @ts-nocheck
import './style.scss';

import React from 'react';
import Iframe from 'react-iframe';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {useCookiesModalVisible, useUseCookiesModal} from "../CookieConsent/cookies";

const APP_VERSION = process.env.REACT_APP_VERSION;

export default function Footer() {
  const { toggle } = useUseCookiesModal()
  return (
    <footer className="Footer">
      <div className="mx-auto p-3 container">
        <div className="flex-row">
          {`© ${moment().year()} `}
          <FormattedMessage id="footer.copyright" defaultMessage={`&copy; ${moment().year()}`} />
          {` ${APP_VERSION}`}
        </div>
        <div
          className="modal fade"
          id="ImprintModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Imprint"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Imprint">
                  Impressum
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>JURA Elektrogeräte Vertriebs-GmbH</h6>
                <br />
                <p>Postanschrift:</p>
                <p>Postfach 99 01 44, 90268 Nürnberg</p>
                <br />
                <p>Besucheranschrift:</p>
                <p>Bamberger Straße 10, 90425 Nürnberg</p>
                <br />
                <p>Telefon: 0911 2525-0</p>
                <p>Telefax: 0911 2525-252</p>
                <p>E-Mail: zentrale@de.jura.com</p>
                <br />
                <p>Geschäftsführer: Horst Nikolaus,</p>
                <p>HRB 8150 Amtsgericht Nürnberg</p>
                <p>Ust-IdNr.: DE 133522311</p>
                <br />
                <p>ein Unternehmen der</p>
                <p>JURA Elektroapparate AG</p>
                <p>Kaffeeweltstr. 10</p>
                <p>4626 Niederbuchsiten, Schweiz</p>
                <br />
                <p>© by JURA Elektrogeräte Vertriebs-GmbH</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="DataPrivacy"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Imprint"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-data-privacy"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Imprint">
                  Datenschutz
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Wir begr&uuml;&szlig;en Sie bei Ihrem Besuch auf unserer
                  Webseite und freuen uns &uuml;ber Ihr Interesse an unserem
                  Unternehmen. Wir nehmen den Schutz Ihrer pers&ouml;nlichen
                  Daten sehr ernst. Wir verarbeiten Ihre Daten in
                  &Uuml;bereinstimmung mit den anwendbaren Rechtsvorschriften
                  zum Schutz personenbezogener Daten, insbesondere der EU
                  Datenschutz-Grundverordnung (EU-DS-GVO).
                  Mithilfe dieser Datenschutzerkl&auml;rung informieren wir Sie
                  umfassend &uuml;ber die Verarbeitung Ihrer personenbezogenen
                  Daten durch die JURA Elektroger&auml;te Vertriebs-GmbH und die
                  Ihnen zustehenden Rechte.&nbsp;&nbsp;
                </p>
                <p>
                  Personenbezogene Daten sind dabei diejenigen Informationen,
                  die eine Identifizierung einer nat&uuml;rlichen Person
                  m&ouml;glich machen. Dazu geh&ouml;ren insbesondere Name,
                  Geburtsdatum, Adresse, Telefonnummer, E-Mail-Adresse aber auch
                  Ihre IP-Adresse.&nbsp;
                </p>
                <p>
                  Anonyme Daten liegen vor, wenn keinerlei Personenbezug zum
                  Nutzer hergestellt werden kann.&nbsp;
                </p>
                <h4>Verantwortliche Stelle und Datenschutzbeauftragter</h4>
                <p>
                  Adresse: JURA Elektroger&auml;te Vertriebs-GmbH, Bamberger
                  Stra&szlig;e&nbsp;10, 90425 N&uuml;rnberg&nbsp;
                  <br /> Kontakt des Datenschutzbeauftragten:&nbsp;
                  <a href="mailto:datenschutz@de.jura.com">
                    datenschutz@de.jura.com
                  </a>
                  &nbsp;
                </p>
                <h4>Ihre Rechte als betroffene Person</h4>
                <p>
                  Zun&auml;chst m&ouml;chten wir Sie an dieser Stelle &uuml;ber
                  Ihre Rechte als betroffene Person informieren. Diese Rechte
                  sind in den Art. 15 - 22 EU-DS-GVO normiert. Dies
                  umfasst:&nbsp;
                </p>
                <ul>
                  <li>Das Recht auf Auskunft (Art. 15 EU-DS-GVO),</li>
                  <li>Das Recht auf L&ouml;schung (Art. 17 EU-DS-GVO),</li>
                  <li>Das Recht auf Berichtigung (Art. 16 EU-DS-GVO),</li>
                  <li>
                    Das Recht auf Daten&uuml;bertragbarkeit (Art. 20 EU-DSGVO),
                  </li>
                  <li>
                    Das Recht auf Einschr&auml;nkung der Datenverarbeitung (Art.
                    18 EU-DS-GVO),
                  </li>
                  <li>
                    Das Widerspruchsrecht gegen die Datenverarbeitung (Art. 21
                    EU-DS-GVO).
                  </li>
                </ul>
                <p>
                  Um diese Rechte geltend zu machen, wenden Sie sich bitte
                  an:&nbsp;
                  <a href="mailto:datenschutz@de.jura.com">
                    datenschutz@de.jura.com
                  </a>
                  .
                </p>
                <p>
                  Gleiches gilt, wenn Sie Fragen zur Datenverarbeitung in
                  unserem Unternehmen haben. Ihnen steht zudem ein
                  Beschwerderecht an eine Datenschutzaufsichtsbeh&ouml;rde
                  zu.&nbsp;
                </p>
                <h4>Widerspruchsrechte&nbsp;</h4>
                <p>
                  Bitte beachten Sie im Zusammenhang mit Widerspruchsrechten
                  folgendes:&nbsp;
                </p>
                <p>
                  Wenn wir Ihre personenbezogenen Daten zum Zwecke der
                  Direktwerbung verarbeiten, haben Sie das Recht, dieser
                  Datenverarbeitung jederzeit ohne Angaben von Gr&uuml;nden zu
                  widersprechen. Dies gilt auch f&uuml;r ein Profiling, soweit
                  es mit der Direktwerbung in Verbindung steht.&nbsp;
                </p>
                <p>
                  Wenn Sie der Verarbeitung f&uuml;r Zwecke der Direktwerbung
                  widersprechen, so werden wir Ihre personenbezogenen Daten
                  nicht mehr f&uuml;r diese Zwecke verarbeiten. Der Widerspruch
                  ist kostenlos und kann formfrei erfolgen, gerne an:&nbsp;
                  <a href="mailto:datenschutz@de.jura.com">
                    datenschutz@de.jura.com
                  </a>
                  .
                </p>
                <p>
                  F&uuml;r den Fall, dass wir Ihre Daten zur Wahrung
                  berechtigter Interessen verarbeiten, k&ouml;nnen Sie dieser
                  Verarbeitung aus Gr&uuml;nden, die sich aus Ihrer besonderen
                  Situation ergeben, jederzeit widersprechen; dies gilt auch
                  f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes
                  Profiling.&nbsp;
                </p>
                <p>
                  Wir verarbeiten Ihre personenbezogenen Daten dann nicht mehr,
                  es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige
                  Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre
                  Interessen, Rechte und Freiheiten &uuml;berwiegen oder die
                  Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder
                  Verteidigung von Rechtsanspr&uuml;chen.&nbsp;
                </p>
                <h4>Zwecke und Rechtsgrundlagen der Datenverarbeitung</h4>
                <p>
                  Bei der Verarbeitung Ihrer personenbezogenen Daten werden die
                  Bestimmungen der EU DS-GVO, des BDSG und alle sonstigen
                  geltenden datenschutzrechtlichen Bestimmungen eingehalten.
                  Rechtsgrundlagen f&uuml;r die Datenverarbeitung ergeben sich
                  insbesondere aus Art. 6 EU-DS-GVO.&nbsp;
                </p>
                <p>
                  Wir verwenden Ihre Daten zur Gesch&auml;ftsanbahnung, zur
                  Erf&uuml;llung vertraglicher und gesetzlicher Pflichten, zur
                  Durchf&uuml;hrung des Vertragsverh&auml;ltnisses, zum Anbieten
                  von Produkten und Dienstleistungen sowie zur St&auml;rkung der
                  Kundenbeziehung, was auch Analysen zu Marketingzwecken und
                  Direktwerbung beinhalten kann.&nbsp;
                </p>
                <p>
                  Ihre Einwilligung kann ebenfalls eine datenschutzrechtliche
                  Erlaubnis darstellen. Hierbei kl&auml;ren wir Sie &uuml;ber
                  die Zwecke der Datenverarbeitung und &uuml;ber Ihr
                  Widerrufsrecht auf. Sollte sich die Einwilligung auch auf die
                  Verarbeitung besonderer Kategorien von personenbezogenen Daten
                  beziehen, werden wir Sie in der Einwilligung ausdr&uuml;cklich
                  darauf hinweisen.
                </p>
                <p>
                  Eine Verarbeitung besonderer Kategorien personenbezogener
                  Daten im Sinne von&nbsp;Art. 9 Absatz 1 EU-DS-GVO erfolgt nur
                  dann, wenn dies aufgrund rechtlicher Vorschriften erforderlich
                  ist und kein Grund zu der Annahme besteht, dass Ihr
                  schutzw&uuml;rdiges Interesse an dem Ausschluss der
                  Verarbeitung &uuml;berwiegt.
                </p>
                <h4>Weitergabe an Dritte</h4>
                <p>
                  Wir werden Ihre Daten nur im Rahmen der gesetzlichen
                  Bestimmungen oder bei entsprechender Einwilligung an Dritte
                  weitergeben. Ansonsten erfolgt eine Weitergabe an Dritte
                  nicht, es sei denn wir sind hierzu aufgrund zwingender
                  Rechtsvorschriften dazu verpflichtet (Weitergabe an externe
                  Stellen wie z. B. Aufsichtsbeh&ouml;rden oder
                  Strafverfolgungsbeh&ouml;rden).&nbsp;
                </p>
                <h4>Empf&auml;nger der Daten/Kategorien von Empf&auml;ngern</h4>
                <p>
                  Innerhalb unseres Unternehmens stellen wir sicher, dass nur
                  diejenigen Personen Ihre Daten erhalten, die diese zur
                  Erf&uuml;llung der vertraglichen und gesetzlichen Pflichten
                  ben&ouml;tigen.
                </p>
                <p>
                  In vielen F&auml;llen unterst&uuml;tzen Dienstleister unsere
                  Fachabteilungen bei der Erf&uuml;llung ihrer Aufgaben. Mit
                  allen Dienstleistern wurde das notwendige
                  datenschutzrechtliche Vertragswerk (z.B. Auftrags-Verarbeitungs-Vertr&auml;ge) abgeschlossen.
                </p>
                <h4>
                  Drittland&uuml;bermittlung/Drittlands&uuml;bermittlungsabsicht
                </h4>
                <p>
                  Eine Daten&uuml;bermittlung in Drittstaaten (au&szlig;erhalb
                  der Europ&auml;ischen Union bzw. des Europ&auml;ischen
                  Wirtschaftsraums) findet nur statt, soweit dies zur
                  Durchf&uuml;hrung des Schuldverh&auml;ltnisses erforderlich,
                  gesetzlich vorgeschrieben ist oder Sie uns dazu Ihre
                  Einwilligung erteilt haben.&nbsp;
                </p>
                <h4>Speicherdauer der Daten</h4>
                <p>
                  Wir speichern Ihre Daten solange diese f&uuml;r den jeweiligen
                  Verarbeitungszweck ben&ouml;tigt werden. Bitte beachten Sie,
                  dass zahlreiche Aufbewahrungsfristen bedingen, dass Daten
                  weiterhin gespeichert werden (m&uuml;ssen). Dies betrifft
                  insbesondere handelsrechtliche oder steuerrechtliche
                  Aufbewahrungspflichten (z. B. Handelsgesetzbuch,
                  Abgabenordnung, etc.). Sofern keine weitergehenden
                  Aufbewahrungspflichten bestehen, werden die Daten nach
                  Zweckerreichung routinem&auml;&szlig;ig gel&ouml;scht.&nbsp;
                </p>
                <p>
                  Hinzu kommt, dass wir Daten aufbewahren k&ouml;nnen, wenn Sie
                  uns hierf&uuml;r Ihre Erlaubnis erteilt haben oder wenn es zu
                  rechtlichen Auseinandersetzungen kommt und wir Beweismittel im
                  Rahmen gesetzlicher Verj&auml;hrungsfristen nutzen, die bis zu
                  drei&szlig;ig Jahre betragen k&ouml;nnen; die
                  regelm&auml;&szlig;ige Verj&auml;hrungsfrist betr&auml;gt drei
                  Jahre.&nbsp;
                </p>
                <h4>Sichere &Uuml;bertragung Ihrer Daten</h4>
                <p>
                  Um die bei uns gespeicherten Daten bestm&ouml;glich gegen
                  zuf&auml;llige oder vors&auml;tzliche Manipulationen, Verlust,
                  Zerst&ouml;rung oder den Zugriff unberechtigter Personen zu
                  sch&uuml;tzen, setzen wir entsprechende technische und
                  organisatorische Sicherheitsma&szlig;nahmen ein. Die
                  Sicherheitslevel werden in Zusammenarbeit mit
                  Sicherheitsexperten laufend &uuml;berpr&uuml;ft und an neue
                  Sicherheitsstandards angepasst.
                </p>
                <p>
                  Der Datenaustausch von und zu unserer Webseite findet jeweils
                  verschl&uuml;sselt statt. Als &Uuml;bertragungsprotokoll
                  bieten wir f&uuml;r unseren Webauftritt HTTPS an, jeweils
                  unter Verwendung der aktuellen
                  Verschl&uuml;sselungsprotokolle. Zudem bieten wir unseren
                  Usern im Rahmen der Kontaktformulare sowie bei Bewerbungen
                  eine Inhaltsverschl&uuml;sselung an. Au&szlig;erdem besteht
                  die M&ouml;glichkeit, alternative Kommunikationswege zu nutzen
                  (z. B. den Postweg).
                </p>
                <h4>Verpflichtung zur Bereitstellung der Daten</h4>
                <p>
                  Diverse personenbezogene Daten sind f&uuml;r die
                  Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung des
                  Schuldverh&auml;ltnisses und der Erf&uuml;llung der damit
                  verbundenen vertraglichen und gesetzlichen Pflichten
                  notwendig. Gleiches gilt f&uuml;r die Nutzung unserer Webseite
                  und der verschiedenen Funktionen, die diese zur Verf&uuml;gung
                  stellt.&nbsp;
                </p>
                <p>
                  In bestimmten F&auml;llen m&uuml;ssen Daten auch aufgrund
                  gesetzlicher Bestimmungen erhoben bzw. zur Verf&uuml;gung
                  gestellt werden. Bitte beachten Sie, dass eine Bearbeitung
                  Ihrer Anfrage oder die Durchf&uuml;hrung des zugrundeliegenden
                  Schuldverh&auml;ltnisses ohne Bereitstellung dieser Daten
                  nicht m&ouml;glich ist.&nbsp;
                </p>
                <h4>Kategorien, Quellen und Herkunft der Daten</h4>
                <p>
                  Welche Daten wir verarbeiten, bestimmt der jeweilige Kontext:
                  Dies h&auml;ngt davon ab, ob Sie z. B. online eine Anfrage
                  senden oder unseren H&auml;ndlerfinder benutzen, um einen
                  H&auml;ndler an Ihrem Wunschort zu finden. Bitte beachten Sie,
                  dass wir Informationen f&uuml;r besondere
                  Verarbeitungssituationen gegebenenfalls auch gesondert an
                  geeigneter Stelle zur Verf&uuml;gung stellen.
                </p>
                <p>
                  <strong>
                    Beim Besuch unserer Webseite k&ouml;nnen wir folgende Daten
                    verarbeiten:
                  </strong>
                </p>
                <ul>
                  <li>IP-Adresse des anfragenden Rechners</li>
                  <li>Datum und Zeitpunkt des Zugriffs/Abrufs</li>
                  <li>Name und URL der abgerufenen Daten</li>
                  <li>
                    Betriebssystem Ihres Rechners und der von Ihnen verwendete
                    Browser
                  </li>
                  <li>
                    Land, von dem aus der Zugriff auf unsere Webseite erfolgt
                  </li>
                  <li>Name Ihres Internetzugangsproviders</li>
                  <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                  <li>Inhalt der Anforderung (konkrete Seite)</li>
                  <li>Zugriffsstatus/HTTP-Statuscode</li>
                  <li>jeweils &uuml;bertragene Datenmenge</li>
                  <li>zuletzt besuchte Webseite</li>
                  <li>Browsereinstellungen</li>
                  <li>Sprache und Version der Browsersoftware</li>
                  <li>aktivierte Browser-Plug-ins</li>
                </ul>
                <p>
                  Als Rechtsgrundlage f&uuml;r die vor&uuml;bergehende
                  Speicherung der Daten und der Logfiles gilt unser berechtigtes
                  Interesse an einem technisch einwandfreien und sicheren Seitenbetrieb,
                  Art. 6 abs.1 S.1 lit. f EU-DS-GVO. Das berechtigte Interesse besteht,
                </p>
                <ul>
                  <li>um die Inhalte unserer Webseite korrekt auszuliefern,</li>
                  <li>
                    um&nbsp;die Inhalte unserer Webseite sowie die Werbung
                    f&uuml;r diese zu optimieren,
                  </li>
                  <li>
                    um&nbsp;unser Angebot und unseren Internetauftritt weiter zu
                    verbessern,
                  </li>
                  <li>um&nbsp;anonyme statistische Daten zu erheben</li>
                </ul>
                <h4>Links zu anderen Anbietern</h4>
                <p>
                  Unsere Webseite enth&auml;lt - deutlich erkennbar - auch Links
                  auf die Internet-Auftritte anderer Unternehmen. Soweit Links
                  zu Webseiten anderer Anbieter vorhanden sind, haben wir auf
                  deren Inhalte keinen Einfluss. Daher kann f&uuml;r diese
                  Inhalte auch keine Gew&auml;hr und Haftung &uuml;bernommen
                  werden. F&uuml;r die Inhalte dieser Seiten ist stets der
                  jeweilige Anbieter oder Betreiber der Seiten
                  verantwortlich.&nbsp;
                </p>
                <p>
                  Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  m&ouml;gliche Rechtsverst&ouml;&szlig;e und erkennbare
                  Rechtsverletzungen gepr&uuml;ft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                  inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                  Bei Bekanntwerden von Rechtsverletzungen werden derartige
                  Links umgehend entfernt.
                </p>
                <h4>Automatisierte Einzelfallentscheidungen</h4>
                <p>
                  Wir nutzen keine rein automatisierten Verarbeitungsprozesse
                  zur Herbeif&uuml;hrung einer Entscheidung
                </p>
                <h4>
                  Cookies (Art. 6 Abs. 1 S.1 lit. f EU-DS-GVO/Art. 6 Abs. 1 S.1 lit a
                  EU-DS-GVO bei Einwilligung)&nbsp;
                </h4>
                <p>
                  Unsere Internetseiten verwenden an mehreren Stellen so
                  genannte Cookies. Sie dienen dazu, unser Angebot
                  nutzerfreundlicher, effektiver und sicherer zu machen. Cookies
                  sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden
                  und die Ihr Browser (lokal auf Ihrer Festplatte)
                  speichert.&nbsp;
                </p>
                <p>
                  Mittels dieser Cookies ist uns eine Analyse dar&uuml;ber
                  m&ouml;glich, wie Nutzer unsere Webseiten benutzen. So
                  k&ouml;nnen wir die Webseiteninhalte den
                  Besucherbed&uuml;rfnissen entsprechend gestalten. Zudem haben
                  wir durch die Cookies die M&ouml;glichkeit, die
                  Effektivit&auml;t einer bestimmten Anzeige zu messen und ihre
                  Platzierung beispielsweise in Abh&auml;ngigkeit von den
                  thematischen Nutzerinteressen erfolgen zu lassen.&nbsp;
                </p>
                <p>
                  Die meisten der von uns verwendeten Cookies sind so genannte
                  &bdquo;Session-Cookies&ldquo;. Diese werden nach Ihrem Besuch
                  automatisch gel&ouml;scht. Permanente Cookies werden
                  automatisch von Ihrem Computer gel&ouml;scht, wenn deren
                  Geltungsdauer (im Regelfall sechs Monate) erreicht ist oder
                  Sie diese vor Ablauf der Geltungsdauer selbst l&ouml;schen.
                </p>
                <p>
                  Die meisten Web-Browser akzeptieren Cookies automatisch. Sie
                  k&ouml;nnen die Einstellungen Ihres Browsers in der Regel aber
                  auch &auml;ndern, wenn Sie die Informationen lieber nicht
                  versenden m&ouml;chten. Sie k&ouml;nnen die Angebote unserer
                  Internetseite dann trotzdem ohne Einschr&auml;nkungen nutzen.
                </p>
                <p>
                  Wir setzen Cookies ein, um unser Angebot nutzerfreundlicher,
                  effektiver und sicherer zu machen. Dar&uuml;ber hinaus setzen
                  wir Cookies ein, mittels derer uns eine Analyse dar&uuml;ber
                  m&ouml;glich ist, wie Nutzer unsere Webseiten benutzen. So
                  k&ouml;nnen wir die Inhalte den Besucherbed&uuml;rfnissen
                  entsprechend gestalten. Zudem haben wir durch die Cookies die
                  M&ouml;glichkeit, die Effektivit&auml;t einer bestimmten
                  Anzeige zu messen und ihre Platzierung beispielsweise in
                  Abh&auml;ngigkeit von den thematischen Nutzerinteressen
                  erfolgen zu lassen.
                </p>
                <p>
                  Cookies werden auf dem Rechner des Nutzers gespeichert und von
                  diesem an unsere Seite &uuml;bermittelt. Daher haben Sie als
                  Nutzer auch die Kontrolle &uuml;ber die Verwendung von
                  Cookies. Durch eine &Auml;nderung der Einstellungen in Ihrem
                  Internetbrowser k&ouml;nnen Sie die &Uuml;bertragung von
                  Cookies deaktivieren oder einschr&auml;nken. Ferner
                  k&ouml;nnen bereits gesetzte Cookies jederzeit &uuml;ber einen
                  Internetbrowser oder andere Softwareprogramme gel&ouml;scht
                  werden. Dies ist in allen g&auml;ngigen Internetbrowsern
                  m&ouml;glich.&nbsp;
                </p>
                <p>
                  Bitte beachten Sie: Deaktivieren Sie die Setzung von Cookies,
                  sind unter Umst&auml;nden nicht alle Funktionen unserer
                  Internetseite vollumf&auml;nglich nutzbar.
                </p>
                <h4>Google Analytics</h4>
                <p>
                  Wenn Sie hierzu einwilligen, nutzt diese Webseite Google Analytics, einen Webanalysedienst
                  der Google Inc. („Google“) 1600 Amphitheatre Parkway, Mountain View, California 94043,
                  USA. Google Analytics verwendet Cookies die auf Ihrem Computer gespeichert werden und
                  die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch diese
                  Cookies erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel
                  an einen Server von Google in den USA übertragen und dort gespeichert.
                </p>
                <p>
                  Um Ihre Daten bestmöglich zu schützen, setzten wir Google Analytics nur unter Aktivierung
                  der IP-Anonymisierung ein. Dadurch wird Ihre IP-Adresse von Google in aller Regel
                  innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                  Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird
                  die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                </p>
                <p>
                  Die Übermittlung Ihrer Daten an Google erfolgt auf Grundlage eines Datenschutzvertrages,
                  den wir mit Google geschlossen haben. Zudem hat sich Google nach dem Transatlantic Data
                  Privacy Framework zertifiziert, sodass von einer Datenverarbeitung auf einem Niveau
                  ausgegangen werden darf, das dem in der EU entspricht.
                </p>
                <p>
                  Google verwendet die von Ihnen erhobenen Informationen, um Ihre Nutzung der Webseite
                  auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
                  mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen uns als
                  Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
                  übermittelte IP-Adresse wird nach Angabe von Google nicht mit anderen Daten von Google
                  zusammengeführt. Wir bitten Sie insofern um Kenntnisnahme der Informationen, die Google
                  unter <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>, 
                  sowie unter <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> bereitstellt und in eigener Verantwortung aktualisiert.                  
                </p>
                <p>
                  Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software 
                  verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                  nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen können.
                </p>
                <p>
                  Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre
                  Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                  Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                  verfügbare Browser-Add-On herunterladen und installieren. Der aktuelle Link ist <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
                </p>
                <p>
                  Nähere Informationen hierzu finden Sie unter <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a> bzw. 
                  unter <a href="http://www.google.com/intl/de/analytics/privacyoverview.htm">http://www.google.com/intl/de/analytics/privacyoverview.htm</a> (allgemeine
                  Informationen zu Google Analytics und Datenschutz). Wir weisen Sie darauf hin, dass auf
                  dieser Webseite Google Analytics um den Code „gat._anonymizeIp();“ erweitert wurde, um
                  eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.
                </p>
                <h4>Google Maps</h4>
                <p>
                  Diese Webseite nutzt auf Grundlage Ihrer Einwilligung, Art. 6 Abs. 1 S.1 lit. a EU-DS-GVO,
                  die Sie über unser Consent-Management steuern und jederzeit widerrufen können, Google
                  Maps zur Darstellung interaktiver Karten und zur Erstellung von Anfahrtsbeschreibungen.
                  Google Maps ist ein Kartendienst von Google Inc., 1600 Amphitheatre Parkway, Mountain
                  View, California 94043, USA. Durch die Nutzung von Google Maps können Informationen
                  über die Benutzung dieser Website einschließlich Ihrer IP-Adresse und der im Rahmen der
                  Routenplanerfunktion eingegebenen (Start-) Adresse an Google in den USA übertragen
                  werden. Die Übermittlung Ihrer Daten an Google erfolgt auf Grundlage eines
                  Datenschutzvertrages, den wir mit Google geschlossen haben. Zudem hat sich Google nach
                  dem Transatlantic Data Privacy Framework zertifiziert, sodass von einer Datenverarbeitung
                  auf einem Niveau ausgegangen werden darf, das dem in der EU entspricht.
                </p>
                <p>
                  - Wenn Sie eine Webseite unseres Internetauftritts aufrufen, die Google Maps enthält und in
                  die Nutzung von Google Maps eingewilligt haben, baut Ihr Browser eine direkte Verbindung
                  mit den Servern von Google auf. Der Karteninhalt wird von Google direkt an Ihren Browser
                  übermittelt und von diesem in die Webseite eingebunden. Daher haben wir keinen Einfluss
                  auf den Umfang der auf diese Weise von Google erhobenen Daten. Entsprechend unserem
                  Kenntnisstand sind dies zumindest folgende Daten:
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;▀&nbsp;&nbsp;&nbsp;&nbsp; Datum und
                  Uhrzeit des Besuchs auf der betreffenden Webseite,
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;▀&nbsp;&nbsp;&nbsp;&nbsp;
                  Internetadresse oder URL der aufgerufenen Webseite,
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;▀&nbsp;&nbsp;&nbsp;&nbsp; IP-Adresse,
                  im Rahmen der Routenplanung eingegebene (Start-)Anschrift.
                </p>
                <p>
                  Auf die weitere Verarbeitung und Nutzung der Daten durch Google haben wir keinen Einfluss
                  und können daher hierfür keine Verantwortung übernehmen. Wir bitten Sie insofern um
                  Kenntnisnahme der Informationen, die Google unter <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>,
                  sowie unter <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> bereitstellt und in eigener
                  Verantwortung aktualisiert.
                </p>
                <p>
                  Wenn Sie nicht möchten, dass Google Maps über unseren Internetauftritt Daten über Sie
                  erhebt, verarbeitet oder nutzt, können Sie in Ihrem Browsereinstellungen JavaScript
                  deaktivieren. In diesem Fall können Sie die Kartenanzeige jedoch nicht nutzen. Zweck und
                  Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
                  Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
                  Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Google
                  (<a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>).
                </p>
                <h4>Nutzerprofile/Webtracking-Verfahren&nbsp;</h4>
                <p>
                  Diese Webseite benutzt auf Grundlage Ihrer Einwilligung, Art. 6 Abs. 1 S.1 lit. a EU-DS-GVO, 
                  die Sie über unser Consent-Management steuern und jederzeit widerrufen können, den
                  Google Tag Manager. Google Tag Manager ist eine Lösung, mit der Vermarkter Webseiten-Tags über eine Oberfläche verwalten können. 
                  Das Tool Tag Manager selbst (das die Tags
                  implementiert) ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das
                  Tool sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen.
                  Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene
                  eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit
                  Google Tag Manager implementiert werden. Wir bitten Sie insofern um Kenntnisnahme der
                  Informationen, die Google unter <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>, sowie unter <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> bereitstellt und in eigener
                  Verantwortung aktualisiert.
                </p>
                <h4>Google ReCaptcha</h4>
                <p>
                  Wir nutzen, sofern Sie hierzu einwilligen, Art. 6 Abs. 1 S.1 lit. a EU-DS-GVO, auf unserer
                  Website Google ReCaptcha (im Folgenden “ReCaptcha“), einen Dienst der Google Inc., 1600
                  Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). ReCaptcha unterstützt
                  uns dabei zu überprüfen, ob die Dateneingabe auf unserer Website durch einen Menschen
                  oder durch ein Programm erfolgt. Hierzu analysiert ReCaptcha das Verhalten des
                  Websitebesuchers anhand von Google definierten Merkmalen. Die Analyse erfolgt
                  automatisch, sobald Sie unsere Website aufrufen. Zur Analyse wertet ReCaptcha 
                  verschiedene Informationen aus (z.B. IP-Adresse, Dauer Ihrer Interaktion mit unserer Seite
                  oder ob Mausbewegungen erfolgen). Wir bitten Sie insofern um Kenntnisnahme der
                  Informationen, die Google unter <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>, sowie unter <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> und 
                  speziell in Bezug auf Google reCAPTCHA unter <a href="https://www.google.com/recaptcha/about/">https://www.google.com/recaptcha/about/</a> bereitstellt und in eigener
                  Verantwortung aktualisiert.
                </p>
                <p>
                  Weitere Informationen zu Google ReCaptcha sowie die Datenschutzerklärung von Google
                  entnehmen Sie folgenden Links: <a href="https://www.google.com/intl/de/policies/privacy/">Datenschutzerklärung – Datenschutzerklärung &</a> <a href="https://www.google.com/intl/de/policies/privacy/">Nutzungsbedingungen – Google</a> und <a href="https://www.google.com/recaptcha/intro/android.html">reCAPTCHA</a>
                </p>
                <p>&nbsp;</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  <FormattedMessage id="footer.close" defaultMessage="Close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto p-3 container text-justify d-flex">
        <a href=" " data-toggle="modal" data-target="#DataPrivacy" className="mx-2">
          <FormattedMessage
            id="osm.dataPrivacy"
            defaultMessage="Datenschutz"
          />
        </a>
        <a href=" " data-toggle="modal" data-target="#ImprintModal" className="mx-2">
          <FormattedMessage id="osm.imprint" defaultMessage="Impressum" />
        </a>
        <a href=" " data-toggle="modal"  className="mx-2" onClick={toggle}>
        <FormattedMessage id="osm.cookies" defaultMessage="Cookies" />
        </a>
      </div>
    </footer>
  );
}
