/* eslint-disable */
// @ts-nocheck
import { useLoadScript } from '@react-google-maps/api';

import { getConfig } from '../../contextual-config';

// const libraries = [];

function useGoogleLoader() {
  const key = getConfig('googleMaps.key');
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key,
    // libraries,
  });
  return {
    isLoaded,
    loadError,
  };
}

export default useGoogleLoader;
