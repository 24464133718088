/* eslint-disable */
// @ts-nocheck
import { push } from 'connected-react-router';
import clamp from 'lodash/fp/clamp';
import concat from 'lodash/fp/concat';
import defaultTo from 'lodash/fp/defaultTo';
import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import mapKeys from 'lodash/fp/mapKeys';
import merge from 'lodash/fp/merge';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import take from 'lodash/fp/take';
import uniqBy from 'lodash/fp/uniqBy';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { Action } from 'redux';

import { getConfig } from '../contextual-config';
import { api } from '../util/request';

const UPDATE_QUERY = 'trader-search/UPDATE_QUERY';
const UPDATE_PERSISTENCE = 'trader-search/UPDATE_PERSISTENCE';

const SEARCH_START = 'trader-search/SEARCH_START';
const SEARCH_SUCCESS = 'trader-search/SEARCH_SUCCESS';
const SEARCH_ERROR = 'trader-search/SEARCH_ERROR';

const SELECT_RESULT = 'trader-search/SELECT_RESULT';
const SET_DETAILS = 'trader-search/SET_DETAILS';
const SET_TRADER = 'trader-search/SET_TRADER';

const RESET_SEARCH = 'trader-search/RESET_SEARCH';

const maxResults = defaultTo(Infinity, getConfig('search.maxResults'));

export type TraderSearchQuery = {
  radius: number;
  street: string;
  postalCode: string;
  city: string;
  lat?: number;
  lng?: number;
};

export type OpeningTime = {
  dayOfWeek: number;
  openingTimes: string;
  closingTimes: string;
};

export type OrgunitDetails = {
  type: number;
  phone: string;
  fax: string;
  email: string;
  homepage: string;
  openingTimes: Array<OpeningTime>;
};

export type OrgunitAddress = {
  addressID: number;
  company: string;
  street: string;
  nr?: number;
  complement?: string;
  zipcode: string;
  city: string;
  state?: string;
  country: string;
  name1?: string;
  name2?: string;
  distance?: number;
  routeLength?: number;
  apiVerified: boolean;
  lat?: number;
  lng?: number;
};

export type Orgunit = {
  orgunitID: number;
  participant: number;
  owner: string;
  address: OrgunitAddress;
  homePage?: string;
  displayname: string;
  gln: string;
  erpnumber: string;
  details: {
    info?: OrgunitDetails;
    sales?: OrgunitDetails;
    support?: OrgunitDetails;
  };
};

const emptyState = {
  query: {
    radius: getConfig('radius.default'),
    street: '',
    postalCode: '',
    city: '',
  },
  orgunitID: null,
  searching: false,
  selected: null,
  detailed: null,
  persist: false,
  results: null,
  hasResetSinceLoad: false,
};

function resolveTraderSelection(results: Orgunit[], actionItem: Orgunit | null) {
  const index = isEmpty(actionItem) ? -1 : findIndex(results, ['orgunitID', actionItem.orgunitID]);
    
    if (index < 0) {
      return null
    }

    return index;
}

export const reducer = (state = emptyState, action: Action) => {
  if (action.type === UPDATE_QUERY) {
    return {
      ...state,
      query: merge(state.query, action.query),
    };
  }

  if (action.type === UPDATE_PERSISTENCE) {
    return {
      ...state,
      persist: action.persist,
    };
  }

  if (action.type === SEARCH_START) {
    return {
      ...state,
      searching: true,
    };
  }

  if (action.type === SEARCH_SUCCESS) {
    return {
      ...state,
      results: action.results,
      searching: false,
    };
  }

  if (action.type === SEARCH_ERROR) {
    return {
      ...state,
      searching: false,
      error: action.error,
    };
  }

  if (action.type === SELECT_RESULT) {    
  
    return {
      ...state,
      selected: resolveTraderSelection(state?.results, action?.item),
    };
  }

  if (action.type === SET_DETAILS) {
    
    return {
      ...state,
      detailed: resolveTraderSelection(state?.results, action?.item),
    };
  }

  if (action.type === SET_TRADER) {
    return {
      ...state,
      orgunitID: action.orgunitID,
    };
  }

  if (action.type === RESET_SEARCH) {
    return {
      ...emptyState,
      query: {
        ...state.query,
        lat: null,
        lng: null,
      },
      persist: state.persist,
      hasResetSinceLoad: true,
    };
  }

  return state;
};

export const updateQuery = (query: TraderSearchQuery) => async dispatch =>
  dispatch({
    type: UPDATE_QUERY,
    query,
  });

export const updatePersistence = (persist: boolean) => async dispatch =>
  dispatch({
    type: UPDATE_PERSISTENCE,
    persist,
  });

export const select = (item: Orgunit) => async dispatch =>
  dispatch({
    type: SELECT_RESULT,
    item,
  });

export const detail = (item: Orgunit) => async dispatch =>
  dispatch({
    type: SET_DETAILS,
    item,
  });

const loadAllTraders = async (
  query: TraderSearchQuery,
  language: string,
  coordinates: {
    lat: number;
    lng: number;
  },
  erpMaterialKeys: string,
  extraParameters?: object,
): Orgunit[] => {
  const limit = getConfig('search.apiPageSize');

  const positionalParams =
    coordinates.lat == null || coordinates.lng == null
      ? {}
      : {
          ...coordinates,
          radius: query.radius,
          returnsDistance: true,
        };

  if (coordinates.lat == null || coordinates.lng == null) {
    coordinates = {};
  }

  const commonParameters = {
    language,
    erpMaterialKeys,
    limit: getConfig('search.apiPageSize'),
    country: getConfig('search.country'),
    sortOrder: erpMaterialKeys
      ? getConfig('search.sortOrderWithProduct')
      : getConfig('search.sortOrder'),
    forceFilterByBusinessRelation: getConfig('search.requireBusinessRelation'),
    brand: getConfig('brand'),
    salesOrgBrand: getConfig('salesOrgBrand'),
    returnsDetails: true,
    returnsErpnumber: true,
    productsInDemonstration: true,
    productsAsClassOfGoods: true,
    returnsOnlyTradersRankedByBrand: true,
    ...positionalParams,
  };

  const results = [];
  let response = null;

  do {
    const params =
      extraParameters == null
        ? {
            ...commonParameters,
            offset: results.length,
          }
        : {
            ...commonParameters,
            offset: 0,
            ...extraParameters,
          };
    const {
      body: { list },
    } = await api.get('/v1/consumer/trader').query(params);
    response = list;
    results.push(...response);
  } while (response.length === limit && response.length < maxResults);

  return results.map(trader => {
    const detailsByType = pipe(
      keyBy('type'),
      mapKeys(
        t =>
          ({
            0: 'info',
            1: 'sales',
            2: 'support',
          }[t]),
      ),
    )(trader.details == null ? [] : trader.details);
    return {
      ...trader,
      details: detailsByType,
    };
  });
};

export const search = (
  query: TraderSearchQuery,
  language: string,
  extraParameters?: object,
) => {
  const thunk = async (dispatch, getState) => {
    dispatch({
      type: SEARCH_START,
    });

    const { current } = getState().demonstrationDevices;

    const radius = clamp(
      getConfig('radius.min'),
      getConfig('radius.max'),
      defaultTo(0, query.radius),
    );

    try {
      const coordinates = pick(['lat', 'lng'], query);
      let converted = await loadAllTraders(
        {
          ...query,
          radius,
        },
        language,

        coordinates,
        get('erpmaterialkey', current),
        extraParameters,
      );

      if (converted.length < getConfig('search.minResults')) {
        converted = pipe(
          concat(
            await loadAllTraders(
              {
                ...query,
                radius: radius + getConfig('radius.step'),
              },
              language,
              coordinates,
              get('erpmaterialkey', current),
              {
                sortOrder: getConfig('search.extraResultsSortOrder'),
                ...(extraParameters || {}),
              },
            ),
          ),
          uniqBy('orgunitID'),
          take(maxResults),
        )(converted);
      }

      return dispatch({
        type: SEARCH_SUCCESS,
        results: converted || [],
        coordinates,
      });
    } catch (error) {
      throw dispatch({
        type: SEARCH_ERROR,
        error,
      });
    }
  };

  thunk.blockUntil = state => !state.demonstrationDevices.loading;

  return thunk;
};

export const changeTrader = (orgunitID: number) => async dispatch => {
  dispatch({
    type: SET_TRADER,
    orgunitID,
  });
};
export const reset = () => async dispatch => {
  dispatch(push('/'));
  dispatch({
    type: RESET_SEARCH,
  });
};

export const searchError = (error: Error) => async dispatch => {
  dispatch({
    type: SEARCH_ERROR,
    error,
  });
};
export const persistence = {
  save(state) {
    const { query, persist } = state.traderSearch;

    if (persist) {
      return {
        query: omit(['lat', 'lng'], query),
        persist,
      };
    }

    return {
      persist,
    };
  },

  load({ query = emptyState.query, persist }) {
    return {
      traderSearch: {
        query,
        persist,
      },
    };
  },
};
