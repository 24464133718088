/* eslint-disable */
// @ts-nocheck
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { classes } from '../../util/cssClasses';
import { ensureSchemaLink } from '../../util/website';

const prettyWebsite = website => {
  try {
    return new URL(website).host;
  } catch (_e) {
    return website;
  }
};

export default function Details({ details, homePage }) {
  const telephone = get('phone', details.sales);
  const fax = get('fax', details.sales);
  const email = get('email', details.sales);

  return (
    <>
      <div
        className={classes('row', {
          'd-none': isEmpty(telephone),
        })}
      >
        <FormattedMessage
          id="details.telephone"
          defaultMessage="Tel.: {telephone}"
          values={{
            telephone,
          }}
        />
      </div>
      <div
        className={classes('row', {
          'd-none': isEmpty(fax),
        })}
      >
        <FormattedMessage
          id="details.fax"
          defaultMessage="Fax: {fax}"
          values={{
            fax,
          }}
        />
      </div>
      <div
        className={classes('row', {
          'd-none': isEmpty(email),
        })}
      >
        <FormattedMessage
          id="details.email"
          defaultMessage="E-mail: {email}"
          values={{
            email: <a href={`mailto:${email}`}>{email}</a>,
          }}
        />
      </div>
      <div
        className={classes('row', {
          'd-none': isEmpty(homePage),
        })}
      >
        <FormattedMessage
          id="details.website"
          defaultMessage="{website}"
          values={{
            website: (
              <a href={ensureSchemaLink(homePage)}>{prettyWebsite(homePage)}</a>
            ),
          }}
        />
      </div>
    </>
  );
}

Details.propTypes = {
  details: PropTypes.shape({
    sales: PropTypes.shape({
      phone: PropTypes.string,
      fax: PropTypes.string,
      email: PropTypes.string,
      homepage: PropTypes.string,
    }),
  }),
};
