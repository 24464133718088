/* eslint-disable */
// @ts-nocheck
import { push } from 'connected-react-router';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import reject from 'lodash/fp/reject';
import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getConfig } from '../../contextual-config';
import locationIcon from '../../img/location.png';
import { requestCurrentLocation } from '../../modules/location';
import { searchError } from '../../modules/trader-search';
import { googleSearchAddress } from '../../util/maps';
import { LOCATION_SEARCH_LINK } from '../../util/website';
import Alert from '../Alert';
import useGoogleMapContext from '../GoogleMapContext/useGoogleMapContext';
import IntegerRangeSelector from '../IntegerRangeSelector';
import Spinner from '../Spinner';
import DisplayProduct from './DisplayProduct';
import SearchFormField from './SearchFormField';

function usefulErrorMessage(error: Error): ReactNode {
  // Mapbox Errors
  if (error.code === 1 && error.code === error.PERMISSION_DENIED) {
    return (
      <FormattedMessage
        id="error.geolocationDenied"
        defaultMessage="Geolocation request denied,
        please allow it in your browser's settings"
      />
    );
  }

  if (error.message === 'Address not found') {
    return (
      <FormattedMessage
        id="error.addressNotFound"
        defaultMessage="Address does not exist or is incorrect"
      />
    );
  }
  // Google Errors

  if (error.message === '_ref4$body$candidates[0] is undefined') {
    return (
      <FormattedMessage
        id="error.addressNotFound"
        defaultMessage="Address does not exist or is incorrect"
      />
    );
  }

  return error.message;
}

// function generateRequest(query) {
//   if (isEmpty(query.city) && isEmpty(query.street)) {
//     return {
//       query: `Germany postal code ${query.postalCode}`,
//       fields: ['name', 'geometry'],
//     };
//   }

//   if (isEmpty(query.postalCode) && isEmpty(query.street)) {
//     return {
//       query: query.city,
//       fields: ['name', 'geometry'],
//     };
//   }

//   return {
//     query: `${query.street} ${query.postalCode} ${query.city}`,
//     fields: ['name', 'geometry'],
//   };
// }

// onSubmit,
function TraderSearchForm({
  formData,
  formDataChange,
  persist,
  persistChange,
  loading,
  errors,
  searchFromLocation,
  search: dispatchSearch,
  setError,
  location,
}) {
  // const { services } = useGoogleMapContext();
  // const { search } = services;

  const form = useRef();
  const fieldEmpty = field => isEmpty(formData[field]);

  async function onSubmit(data) {
    // const request = generateRequest(data);
    const query = {
      zipcode: data.postalCode,
      street: data.street,
      city: data.city,
      country: 'de',
      radius: data.radius,      
    }

    const { lat, lng } = await googleSearchAddress(query, setError);

    if (lat, lng) {
      dispatchSearch(lat, lng, query, location);
    }
    // if (search) {
      // try {
        
        // search.findPlaceFromQuery(request, (results, status) => {
        //   if (status === 'ZERO_RESULTS') {
        //     setError(
        //       new Error('Die angegebene Adresse konnte nicht gefunden werden.'),
        //     );
        //   } else {
        //     const [
        //       {
        //         geometry: {
        //           location: { lat, lng },
        //         },
        //       },
        //     ] = results;
        //     dispatchSearch(lat(), lng(), query, location);
        //   }
        // });
      // } catch (error) {
      //   setError(error);
      // }
    // }
  }

  return (
    <form
      ref={form}
      className="no-gutters"
      onSubmit={ev => {
        if (onSubmit != null) {
          onSubmit(formData);
        }

        ev.preventDefault();
      }}
    >
      <div className="row">
        <h5 className="col-6">
          <FormattedMessage id="search.title" defaultMessage="Trader Search" />
        </h5>
        <button
          className="btn btn-go-top col-6"
          type="button"
          onClick={searchFromLocation.bind(null, formData)}
        >
          <img src={locationIcon} alt="View Products" />
          <FormattedMessage
            className="ml-2"
            id="search.searchByGeolocation"
            defaultMessage="Find near me"
          />
        </button>
      </div>
      <div className="col-12 mb-2">
        <FormattedMessage
          id="search.subtitle"
          defaultMessage="Geben Sie Ihre gewünschte Postleitzahl oder einen Ortsnamen ein."
        />
      </div>
      <fieldset disabled={loading} className="no-gutters">
        <SearchFormField
          form={form}
          name="street"
          fieldProps={{
            type: 'text',
          }}
          formData={formData}
          formDataChange={formDataChange}
        />
        <SearchFormField
          form={form}
          name="postalCode"
          required={fieldEmpty('city')}
          fieldProps={{
            type: 'text',
            required: true,
          }}
          formData={formData}
          formDataChange={formDataChange}
        />
        <SearchFormField
          form={form}
          name="city"
          required={fieldEmpty('postalCode')}
          fieldProps={{
            type: 'text',
            required: true,
          }}
          formData={formData}
          formDataChange={formDataChange}
        />
      </fieldset>
      <div className="form-group row">
        <label className="col-6 col-sm col-form-label">
          <FormattedMessage id="search.radius" defaultMessage="Radius" />
        </label>
        <IntegerRangeSelector
          className="col-6 col-sm-8 col-lg-3"
          value={formData.radius}
          valueChange={radius =>
            formDataChange({
              ...formData,
              radius,
            })
          }
          start={getConfig('radius.min')}
          end={getConfig('radius.max')}
          step={getConfig('radius.step')}
          options={getConfig('radius.options')}
        />
      </div>
      <div className="row form-group form-check">
        <input
          className="form-check-input"
          id="trader-search-persist"
          type="checkbox"
          checked={persist}
          onChange={ev => persistChange(ev.target.checked)}
        />
        <label className="form-check-label" htmlFor="trader-search-persist">
          <small>
            <FormattedMessage id="search.persist" defaultMessage="Persist" />
          </small>
        </label>
      </div>
      <div className="product-row bg-shade">
        <DisplayProduct showImage />
      </div>
      <div className="row align-items-center pt-3">
        <small className="col">
          <FormattedMessage
            id="search.privacy"
            defaultMessage="Your data is not analyzed"
          />
          <br />
          *: <FormattedMessage id="search.required" defaultMessage="Required" />
        </small>
        <Spinner spinning={loading} className="light" />
        <button
          className="btn btn-go btn-go-bg"
          type="submit"
          disabled={loading}
        >
          <FormattedMessage id="search.search" defaultMessage="Search" />
        </button>
      </div>
      {errors.map(error => (
        <Alert type="danger" className="mt-3" key={error.message}>
          {usefulErrorMessage(error)}
        </Alert>
      ))}
    </form>
  );
}

export default connect(
  state => ({
    location: state.router.location,
    loading: state.traderSearch.searching,
    errors: reject(isNil, [state.traderSearch.error, state.location.error]),
  }),
  dispatch => ({
    search(lat, lng, query, location) {
      return dispatch(
        push({
          ...location,
          pathname: LOCATION_SEARCH_LINK.fill({
            lat,
            lng,
            radius: query.radius,
          }),
        }),
      );
    },

    getLocation() {
      return dispatch(requestCurrentLocation());
    },

    setError(error) {
      return dispatch(searchError(error));
    },
  }),
  (
    { location, ...stateProps },
    { search, getLocation, setError, ...dispatchProps },
    ownProps,
  ) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    location,
    setError,
    search,

    // async onSubmit(query) {
    //   try {
    //     const { lat, lng } = await googleSearchAddress(query);        
    //     return await search(lat, lng, query, location);
    //   } catch (error) {
    //     setError(error);
    //   }
    // },

    async searchFromLocation(query) {
      const { lat, lng } = await getLocation();      
      return await search(lat, lng, query, location);
    },
  }),
  {
    pure: false,
  },
)(TraderSearchForm);
