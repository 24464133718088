import { Environment } from './Environment';

export * from './Environment';

export const DEFAULT_ENVIRONMENT = Environment.DEVELOPMENT;

const readEnvironmentFromProcess = (): string => {
  const { NODE_ENV, REACT_APP_NODE_ENV } = process.env;
  return REACT_APP_NODE_ENV || NODE_ENV || DEFAULT_ENVIRONMENT;
};

let environment = readEnvironmentFromProcess();

export const getEnvironment = (): Environment =>
  <Environment>(environment || DEFAULT_ENVIRONMENT);

export const setEnvironment = (env: Environment): void => {
  environment = env;
};

export const isDevelopment = (): boolean =>
  getEnvironment() === Environment.DEVELOPMENT;
