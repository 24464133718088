/* eslint-disable */
// @ts-nocheck
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getConfig } from '../../contextual-config';
import { change, contact } from '../../modules/contact';
import { classes } from '../../util/cssClasses';
import FormField from '../FormField';
import Spinner from '../Spinner';
import Situation from './Situation';

export class ContactForm extends Component {
  static propTypes = {
    recipient: PropTypes.shape({
      orgunitID: PropTypes.number,
    }),
    loading: PropTypes.bool.isRequired,
    submit: PropTypes.func,
    change: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.recaptcha = null;
    this.state = {
      name: '',
      sender: '',
      subject: '',
      content: '',
      captcha: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, change } = this.props;
    const { captcha } = this.state;

    if (prevState.captcha != null && captcha == null) {
      this.recaptcha.reset();
    }

    if (prevProps.loading && !loading) {
      this.setState({
        captcha: null,
      });
    }

    if (!isEqual(prevState, this.state) && captcha != null) {
      change();
    }
  }

  render() {
    const { loading, success, recipient, submit = noop } = this.props;
    const { name, sender, subject, content, captcha } = this.state;

    const contentFieldId = uniqueId('ContactForm-content');

    return (
      <form
        className="ContactForm"
        onSubmit={ev => {
          ev.preventDefault();
          submit({
            recipientId: recipient.orgunitID,
            name,
            sender,
            subject,
            content,
            captcha,
          });
        }}
      >
        <fieldset className="no-gutters" disabled={loading}>
          <FormField
            label={
              <FormattedMessage
                id="contact.sender"
                defaultMessage="Your name"
              />
            }
            name="name"
            required
            value={name}
            valueChange={name =>
              this.setState({
                name,
              })
            }
          />

          <FormField
            label={
              <FormattedMessage
                id="contact.email"
                defaultMessage="Your email"
              />
            }
            name="email"
            required
            value={sender}
            valueChange={sender =>
              this.setState({
                sender,
              })
            }
          />

          <FormField
            label={
              <FormattedMessage id="contact.subject" defaultMessage="Subject" />
            }
            name="subject"
            required
            value={subject}
            valueChange={subject =>
              this.setState({
                subject,
              })
            }
          />

          <div className="form-group row">
            <label className="col col-form-label" htmlFor={contentFieldId}>
              <FormattedMessage id="contact.content" defaultMessage="Message" />
              *
            </label>
            <textarea
              className="form-control"
              id={contentFieldId}
              required
              value={content}
              onChange={ev =>
                this.setState({
                  content: ev.target.value,
                })
              }
            />
          </div>

          <div className="row flex-column align-items-end captcha-wrapper">
            <Recaptcha
              ref={recaptcha => (this.recaptcha = recaptcha)}
              sitekey={getConfig('recaptchaPublicKey')}
              verifyCallback={captcha =>
                this.setState({
                  captcha,
                })
              }
            />
          </div>

          <div className="row mt-3 justify-content-end align-items-center">
            <Spinner spinning={loading} className="light" />

            <button
              className={classes('btn btn-go float-right', {
                'd-none-sm': loading,
              })}
              type="submit"
              disabled={captcha == null}
            >
              <FormattedMessage id="contact.send" defaultMessage="Send" />
            </button>
          </div>

          <Situation
            className="col p-2 m-0"
            loading={loading}
            success={success}
          />
        </fieldset>
      </form>
    );
  }
}

export default connect(
  state => ({
    loading: state.contact.sending,
    success: state.contact.success,
  }),
  dispatch =>
    bindActionCreators(
      {
        submit: contact,
        change,
      },
      dispatch,
    ),
)(ContactForm);
