/* eslint-disable */
// @ts-nocheck
import { Circle, useGoogleMap } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { getConfig } from '../../contextual-config';
import useGoogleMapContext from '../GoogleMapContext/useGoogleMapContext';
import TraderMarkers from './TraderMarkers';

function calculateZoom(radius) {
  if (radius === 5) {
    return 11.5;
  }

  return 11.5 - (radius / 5) * 0.25;
}

function MapInterface() {
  const {
    traders,
    origin,
    radius,
    selected,
    select,
    setServices,
    zoom,
    setZoom,
  } = useGoogleMapContext();
  const gmap = useGoogleMap();
  const { markerPixelOffset } = getConfig('googleMaps');

  useDeepCompareEffect(() => {     
    if (origin) {
      const { lat, lng } = origin;
      gmap.panTo({
        lat,
        lng,
      });
      gmap.panBy(markerPixelOffset, 0);
    }
  }, [origin]);

  useEffect(() => {    
    if (selected) {
      const {
        address: { lat, lng },
      } = selected;
      gmap.panTo({
        lat,
        lng,
      });
      gmap.panBy(markerPixelOffset, 0);
    }
  }, [selected]);

  useEffect(() => {
    setZoom(calculateZoom(radius));
  }, [radius]);
  
  return (
    <>
      {setServices(window, gmap)}
      <TraderMarkers selected={selected} traders={traders} select={select} />
      {origin && (
        <Circle
          center={origin}
          radius={radius * 1000}
          options={{
            strokeColor: 'blue',
            strokeWeight: 1,
            fillOpacity: 0,
          }}
        />
      )}
    </>
  );
}

export default MapInterface;
