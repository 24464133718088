/* eslint-disable */
// @ts-nocheck
import { Action } from 'redux';

import { api } from '../util/request';

const CONTACT_USER_CHANGE = 'contact/CONTACT_USER_CHANGE';

const CONTACT_START = 'contact/CONTACT_START';
const CONTACT_SUCCESS = 'contact/CONTACT_SUCCESS';
const CONTACT_ERROR = 'contact/CONTACT_ERROR';

export type ContactData = {
  recipientId: number;
  name: string;
  sender: string;
  subject: string;
  content: string;
  captcha?: string;
};

const emptyState = {
  sending: false,
  success: null,
};

export const reducer = (state = emptyState, action: Action) => {
  if (action.type === CONTACT_START) {
    return {
      ...state,
      sending: true,
      success: null,
    };
  }

  if (action.type === CONTACT_SUCCESS) {
    return {
      ...state,
      sending: false,
      success: true,
    };
  }

  if (action.type === CONTACT_ERROR) {
    return {
      ...state,
      sending: false,
      success: false,
    };
  }

  if (action.type === CONTACT_USER_CHANGE) {
    return {
      ...emptyState,
    };
  }

  return state;
};

export const contact = (data: ContactData) => async dispatch => {
  dispatch({
    type: CONTACT_START,
  });

  try {
    await api.post('/v1/consumer/trader/send-contact', {
      recaptchaCode: data.captcha,
      orgunitID: data.recipientId,
      userMail: data.sender,
      userFullName: data.name,
      subject: data.subject,
      message: data.content,
      orgunitDetailsType: 0, // Informational
    });

    return dispatch({
      type: CONTACT_SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: CONTACT_ERROR,
      error,
    });
  }
};

export const change = () => async dispatch =>
  dispatch({
    type: CONTACT_USER_CHANGE,
  });
