/* eslint-disable */
// @ts-nocheck
import './style.scss';

import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getConfig } from '../../contextual-config';
import { detail, select } from '../../modules/trader-search';
import { classes } from '../../util/cssClasses';
import { createRouteLink } from '../../util/maps';
import { ensureSchemaLink, getRetailerWebsite } from '../../util/website';
import Address from '../Address';
import AddressDistance from '../Distance/AddressDistance';
import useGoogleMapContext from '../GoogleMapContext/useGoogleMapContext';
import Details from './Details';
import ItemLink from './ItemLink';
import Logo from './Logo';

export function RetailerListItem({
  model,
  sourceAddress,
  selected,
  detailed,
  onSelect,
  onOpen,
}) {
  const email = get('email', model.details.sales);
  const disableOrgunitLogo = getConfig('features.disableOrgunitLogo');
  const showLogo = !disableOrgunitLogo;
  const { services } = useGoogleMapContext();
  const { distance } = services;
  return (
    <div
      className={classes('RetailerListItem clickable', {
        selected: selected || detailed,
        detailed,
      })}
    >
      <Details model={model} selected={detailed} />
      <div
        className="p-3 no-gutters information"
        onMouseEnter={onSelect}
        onMouseDown={onOpen}
      >
        <div className="col">
          <div className="underline">{model.address.company}</div>
          <Address address={model.address} />
          <AddressDistance trader={model} service={distance} />
        </div>
        <div className="actions d-none d-sm-flex">
          <ItemLink
            href={ensureSchemaLink(getRetailerWebsite(model))}
            className="website"
          >
            Website
          </ItemLink>
          {!isEmpty(email) && (
            <ItemLink href={`mailto:${email}`} className="email">
              E-mail
            </ItemLink>
          )}
          <ItemLink
            href={createRouteLink(sourceAddress, model.address)}
            className="route"
          >
            Route
          </ItemLink>
        </div>

        {showLogo && (
          <Logo orgunit={model} className="photo d-none d-sm-flex" />
        )}
      </div>
    </div>
  );
}

RetailerListItem.propTypes = {
  model: PropTypes.shape({
    homepage: PropTypes.string,
    address: PropTypes.shape({
      company: PropTypes.string,
      distance: PropTypes.number,
    }),
    details: PropTypes.shape({
      sales: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
  }),
};

export default connect(
  (state, props) => {
    const {
      query: { lat, lng },
      selected,
      detailed,
      results,
    } = state.traderSearch;

    return {
      sourceAddress: `${lat}, ${lng}`,
      selected: results[selected] === props.model,
      detailed: results[detailed] === props.model,
    };
  },
  (dispatch, props) => ({
    onSelect: () => dispatch(select(props.model)),
    onOpen: () => dispatch(detail(props.model)),
  }),
)(RetailerListItem);
