/* eslint-disable */
// @ts-nocheck
import './style.scss';

import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React from 'react';

import closeIcon from '../../img/close.png';
import { classes } from '../../util/cssClasses';

export default function InlineWindow({
  title: Title = () => null,
  close = noop,
  className,
  children,
}) {
  return (
    <div
      className={classes('InlineWindow', className)}
      onClick={ev => ev.stopPropagation()}
    >
      <div className="InlineWindow-title">
        <Title />
        <button type="button" className="btn bg-none" onClick={close}>
          <img src={closeIcon} alt="View Products" />
        </button>
      </div>
      <div className="InlineWindow-body">{children}</div>
    </div>
  );
}

InlineWindow.propTypes = {
  children: PropTypes.node,
};
