/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormField from '../FormField';

export default function SearchFormField({
  name,
  required,
  fieldProps,
  formData,
  formDataChange,
}) {
  return (
    <FormField
      label={<FormattedMessage id={`search.${name}`} defaultMessage={name} />}
      name={name}
      required={required}
      fieldProps={fieldProps}
      value={formData[name]}
      valueChange={value =>
        formDataChange({
          ...formData,
          [name]: value,
        })
      }
    />
  );
}

SearchFormField.propTypes = {
  form: PropTypes.shape({
    props: PropTypes.shape({
      formData: PropTypes.object,
      formDataChange: PropTypes.func,
    }),
  }),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
};
