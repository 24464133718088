/* eslint-disable */
// @ts-nocheck
import React from 'react';

import logo from '../../img/logo.png';

export default function Header() {
  return (
    <header className="mx-auto p-3 container">
      <a href={process.env.PUBLIC_URL || '/'}>
        <img src={logo} className="m-1" alt="Logo" />
      </a>
    </header>
  );
}
