/* eslint-disable */
// @ts-nocheck
import './style.scss';

import contains from 'lodash/fp/contains';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../util/cssClasses';

export default function TabArea({ children, selected, onChange, className }) {
  const keys = React.Children.map(children, get('key'));

  if (!contains(selected, keys)) {
    selected = keys[0];
  }

  return (
    <div className={classes('TabArea', className)}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          onClick: () => onChange(child.key),
          selected: selected === child.key,
        }),
      )}
    </div>
  );
}
TabArea.propTypes = {
  onChange: PropTypes.func,

  selected: PropTypes.any,
};
