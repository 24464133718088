/* eslint-disable */
// @ts-nocheck
/**
 * Formats the key to the persisted format.
 * */
export const formatKey = key => `mmw:smart-retail-locator:persistence:${key}2`;

type SpecificationItem<S, L> = {
  load: (loading: L) => S;
  save: (state: S) => L;
};

type PersistenceSpecification = {
  [key: string]: SpecificationItem<any, any>;
};

type LoadedState = Record<string, any>;

/**
 * Loads the persisted local state, based on the given `spec`.
 *
 * The returned object contains a key for every entry of the
 * `spec` which had a stored value. Missing stored state equals
 * missing key on the returned object.
 * */
export function loadState(spec: PersistenceSpecification): LoadedState {
  let reconstructed = {};

  for (const key in spec) {
    if (!spec.hasOwnProperty(key)) {
      continue;
    }

    try {
      const existing = localStorage.getItem(formatKey(key));

      if (existing !== undefined) {
        const obj = JSON.parse(existing);
        reconstructed = {
          ...reconstructed,
          ...spec[key].load(obj),
        };
      }
    } catch (_) {
      break;
    }
  }

  return reconstructed;
}

/**
 * Saves the given state to the local persistence.
 *
 * Only properties specified in the `spec` parameter will be
 * included in the persisted state.
 * */
export function saveState(
  spec: PersistenceSpecification,
  state: LoadedState,
): void {
  for (const key in spec) {
    if (!spec.hasOwnProperty(key)) {
      continue;
    }

    try {
      const serializable = spec[key].save(state);
      const text = JSON.stringify(serializable);
      localStorage.setItem(formatKey(key), text);
    } catch (error) {
      console.error(
        `An error ocurred when serializing the state for "${key}".`,
        error,
      );
    }
  }
}
