import noop from 'lodash/noop';
import { useCallback } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

class CookieState {
  modalVisible = false;

  bannerVisible = true;

  marketing = false;

  functional = true;

  analytics = false;

  googleReCaptcha = false;

  googleMaps = false;

  setCookieConfig: (name: keyof CookieState, value: boolean) => void = noop;
}

export const useCookieStore = create<CookieState>()(
  devtools(
    persist(
      set => {
        const INITIAL_STATE = new CookieState();
        INITIAL_STATE.setCookieConfig = (name, value) => {
          set({ [name]: value });
        };
        return INITIAL_STATE;
      },
      {
        name: 'cookie-storage-2023',
      },
    ),
  ),
);

export function useGoogleMapsCookies(): {
  value: boolean;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.googleMaps);
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  const setValue = useCallback(
    (newValue: boolean) => {
      setCookieConfig('googleMaps', newValue);
    },
    [setCookieConfig],
  );
  return { value, setValue };
}
export function useGoogleReCaptchaCookies(): {
  value: boolean;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.googleReCaptcha);
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  const setValue = useCallback(
    (newValue: boolean) => {
      setCookieConfig('googleReCaptcha', newValue);
    },
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useAnalyticsCookies(): {
  value: boolean;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.analytics);
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('analytics', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useMarketingCookies(): {
  value: boolean;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.marketing);
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('marketing', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useFunctionalCookies(): {
  value: boolean;
  setValue: (value: boolean) => void;
} {
  const value = useCookieStore(state => state.functional);
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  const setValue = useCallback(
    (newValue: boolean) => setCookieConfig('functional', newValue),
    [setCookieConfig],
  );
  return { value, setValue };
}

export function useCookiesModalVisible(): boolean {
  return useCookieStore(state => state.modalVisible);
}
export function useCookiesBannerVisible(): boolean {
  return useCookieStore(state => state.bannerVisible);
}

export function useUseCookiesModal(): { toggle: () => void; value: boolean } {
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);
  const value = useCookieStore(state => state.modalVisible);
  const toggle = useCallback(() => {
    setCookieConfig('modalVisible', !value);
    if (!value) setCookieConfig('bannerVisible', false);
  }, [setCookieConfig, value]);
  return {
    toggle,
    value,
  };
}
export function useUseCookiesBanner(): { toggle: () => void; value: boolean } {
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);
  const value = useCookieStore(state => state.bannerVisible);
  const toggle = useCallback(() => {
    setCookieConfig('bannerVisible', !value);
  }, [setCookieConfig, value]);
  return {
    toggle,
    value,
  };
}

export function useAcceptAllCookiesAndCloseBanner(): () => void {
  const setCookieConfig = useCookieStore(state => state.setCookieConfig);

  return useCallback(() => {
    setCookieConfig('bannerVisible', false);
    setCookieConfig('modalVisible', false);
    setCookieConfig('functional', true);
    setCookieConfig('googleReCaptcha', true);
    setCookieConfig('googleMaps', true);
    setCookieConfig('marketing', true);
    setCookieConfig('analytics', true);
  }, [setCookieConfig]);
}
