/* eslint-disable */
// @ts-nocheck
import filter from 'lodash/fp/filter';
import flatMap from 'lodash/fp/flatMap';
import flattenDeep from 'lodash/fp/flattenDeep';
import isNil from 'lodash/fp/isNil';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import negate from 'lodash/fp/negate';
import pipe from 'lodash/fp/pipe';
import toPairs from 'lodash/fp/toPairs';

type CssClassList = [
  | {
      [key: string]: boolean;
    }
  | (string | void)
  | (() => CssClassList | [CssClassList]),
];

function objectToClasses(obj) {
  return pipe(
    toPairs,
    filter(([_, active]) => active),
    map(0),
  )(obj);
}

export function classes(...args: CssClassList) {
  return pipe(
    flattenDeep,
    flatMap(c => {
      switch (typeof c) {
        case 'function':
          return classes(c());

        case 'object':
          return objectToClasses(c);

        default:
          return c;
      }
    }),
    filter(negate(isNil)),
    join(' '),
  )(args);
}
