/* eslint-disable */
// @ts-nocheck
import { GoogleMap } from '@react-google-maps/api';
import React from 'react';

import useGoogleMapContext from '../GoogleMapContext/useGoogleMapContext';
import MapInterface from './MapInterface';

function renderMap(center, zoom) {
  return (
    <GoogleMap
      id="google-map"
      className="TraderSearchMap"
      center={center}
      zoom={zoom}
    >
      <MapInterface />
    </GoogleMap>
  );
}

function TraderSearchMap() {
  const { isLoaded, loadError, center, zoom } = useGoogleMapContext();
  return (
    (loadError &&
      console.log('error on loading google maps script', loadError)) ||
    (isLoaded ? renderMap(center, zoom) : null)
  );
}

export default TraderSearchMap;
