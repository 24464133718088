/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import DisplayProduct from './DisplayProduct';
import TraderSearchForm from './TraderSearchForm';

export default function SearchContent({
  query,
  queryChange,
  persist,
  persistChange,
  results,
}) {
  const content =
    results == null ? (
      <TraderSearchForm
        formData={query}
        formDataChange={queryChange}
        persist={persist}
        persistChange={persistChange}
      />
    ) : (
      <>
        <h5 className="expander">
          <FormattedMessage
            id="search.openTitle"
            defaultMessage="Open Trader Search"
          />
        </h5>
        <div className="product-row bg-shade">
          <DisplayProduct showImage={false} />
        </div>
      </>
    );

  return <div className="p-3">{content}</div>;
}

SearchContent.propTypes = {
  query: PropTypes.object.isRequired,
  queryChange: PropTypes.func.isRequired,
  persist: PropTypes.bool.isRequired,
  persistChange: PropTypes.func.isRequired,
  results: PropTypes.array,
};
