/* eslint-disable */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import Paginator from '../Paginator';
import { PAGE_SIZE } from '.';

export default function Foot({ count, page, change }) {
  if (count === 0) {
    return null;
  }

  return (
    <div className="row p-3 justify-content-end">
      <Paginator
        className="d-inline-block ml-auto"
        page={page}
        count={count}
        pageSize={PAGE_SIZE + 1}
        pageChange={change}
      />
    </div>
  );
}

Foot.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  change: PropTypes.func,
};
