/* eslint-disable */
// @ts-nocheck
import deepmerge from 'deepmerge';
import get from 'lodash/get';

import defaultConfig from '../config/default.json';
import development from '../config/development.json';
import production from '../config/production.json';
import staging from '../config/staging.json';

let contextualConfig;

function setConfig() {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'live': {
      contextualConfig = deepmerge(defaultConfig, production);
      break;
    }

    case 'staging': {
      contextualConfig = deepmerge(defaultConfig, staging);
      break;
    }

    case 'development': {
      contextualConfig = deepmerge(defaultConfig, development);
      break;
    }

    default: {
      contextualConfig = deepmerge(defaultConfig, production);
    }
  }
}

setConfig();
export function getConfig(item) {  
  return get(contextualConfig, item);
}

