/* eslint-disable */
// @ts-nocheck
import { get, noop } from 'lodash';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { select } from '../../modules/trader-search';
import Context, { INITIAL_VALUE } from './context';
import useGoogleLoader from './useGoogleLoader';

function Provider({ children, selected, traders, ...props }) {
  const [search, setSearch] = useState(null);
  const [distance, setDistance] = useState(null);
  const [zoom, setZoom] = useState(INITIAL_VALUE.zoom);
  const selectedTrader = useMemo(() => {
    if (typeof selected === 'number') return traders[selected];
    return null;
  }, [selected]);
    
  function setServices(window, gmap) {
    const maps = get(window, 'google.maps', null);
    const places = get(maps, 'places', null);

    if (gmap) {
      if (places && !search) setSearch(noop);
      if (maps && !distance) setDistance(new maps.DistanceMatrixService());
    }
  }

  const config = useMemo(() => ({
    ...INITIAL_VALUE,
    ...props,
    traders,
    selected: selectedTrader,
    services: {
      search,
      distance,
    },
    setServices,
    zoom,
    setZoom,
  }), [
    setServices,
    zoom,
    setZoom,
    search,
    distance,
    selectedTrader,
    traders,
    props
  ])

  return (
    <Context.Provider
      value={config}
    >
      {children}
    </Context.Provider>
  );
}

function GoogleMapContext(props) {
  const { isLoaded, loadError } = useGoogleLoader();
  return Provider({
    isLoaded,

    loadError,
    ...props,
  });
}

export default compose(
  connect(
    state => ({
      traders: state.traderSearch.results,
      selected: state.traderSearch.selected,
      origin: pipe(pick(['lat', 'lng']), ({ lat, lng }) =>
        lat == null || lng == null
          ? null
          : {
              lat,
              lng,
            },
      )(state.traderSearch.query),
      radius: state.traderSearch.query.radius,
    }),

    dispatch => ({
      select: trader => dispatch(select(trader)),
    }),
  ),
)(GoogleMapContext);
