/* eslint-disable */
// @ts-nocheck
import { Action } from 'redux';

const LAYOUT_SET_MAIN_HEIGHT = 'layout/SET_MAIN_HEIGHT';

const emptyState = {
  mainHeight: null,
};

export const reducer = (state = emptyState, action: Action) => {
  if (action.type === LAYOUT_SET_MAIN_HEIGHT) {
    return {
      ...state,
      mainHeight: action.height,
    };
  }

  return state;
};

export const changeMainHeight = (height: number) => async dispatch =>
  dispatch({
    type: LAYOUT_SET_MAIN_HEIGHT,
    height,
  });
