/* eslint-disable */
// @ts-nocheck
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import throttle from 'lodash/fp/throttle';
import isFunction from 'lodash/isFunction';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { getConfig } from '../contextual-config';
import rootReducer from '../modules';
import { persistence as localePersistence } from '../modules/locale';
import { persistence as searchPersistence } from '../modules/trader-search';
import { asyncDependencyMiddleware } from './asyncDependency';
import { loadState, saveState } from './persistence';

export const history = createHistory({
  basename: process.env.PUBLIC_URL,
});

const serializationSpec = {
  traderSearch: searchPersistence,
  locale: localePersistence,
};

const enhancers = [];
const middleware = [
  routerMiddleware(history),
  asyncDependencyMiddleware,
  thunk,
];

if (process.env.NODE_ENV === 'development') {
  const extensions = [window.__REDUX_DEVTOOLS_EXTENSION__];
  // window.devToolsExtension,

  for (const extension of extensions) {
    if (!isFunction(extension)) {
      continue;
    }

    enhancers.push(extension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const makeStore = () => {
  const store = createStore(
    connectRouter(history)(rootReducer),
    loadState(serializationSpec),
    composedEnhancers,
  );

  store.subscribe(
    throttle(getConfig('state.savePeriod'), () => {
      saveState(serializationSpec, store.getState());
    }),
  );

  return store;
};
