/* eslint-disable */
// @ts-nocheck
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    const { location } = this.props;
    return (
      <div className="small-notice p-5 mx-auto bg-gradient">
        <h2>
          <FormattedMessage
            id="notfound.title"
            defaultMessage="Page Not Found"
          />
        </h2>
        <p>
          <FormattedMessage
            id="notfound.blurb"
            defaultMessage="We couldn't find a page on this address:"
          />
        </p>
        <pre>{location.pathname}</pre>
        <Link to="/" className="btn btn-light">
          <i className="fa fa-home mr-2" />
          <FormattedMessage
            id="notfound.return"
            defaultMessage="Back to Home"
          />
        </Link>
      </div>
    );
  }
}
