/* eslint-disable */
// @ts-nocheck
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { fetchAccurateDistances } from '../../util/maps';
import Distance from '.';

class AddressDistance extends React.Component {
  constructor() {
    super();
    this.state = {
      routeLength: null,
      loading: false,
    };
  }

  componentDidMount() {
    const { coordinates } = this.props;

    if (isEmpty(coordinates)) {
      return;
    }

    this.loadRouteLength();
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    const { coordinates, trader } = this.props;
    const { coodinates: prevCoordinates, trader: prevTrader } = prevProps;

    if (isEqual(coordinates, prevCoordinates) && isEqual(trader, prevTrader)) {
      return;
    }

    this.loadRouteLength();
  }

  loadRouteLength() {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    const { trader, coordinates, service } = this.props;

    if (isEmpty(coordinates)) {
      return;
    }

    this.setState({
      loading: true,
    });
    fetchAccurateDistances(service, coordinates, [trader])
      .then(accurate => {
        this.setState({
          routeLength: accurate[0].address.routeLength,
        });
      })
      .catch(console.warn.bind(console, 'Failed fetching accurate distances:'));
  }

  render() {
    const { routeLength } = this.state;
    const {
      trader: {
        address: { distance },
      },
    } = this.props;
    return (
      <>
        {routeLength != null && (
          <div>
            <Distance value={routeLength} labelSpecifier="route" />
          </div>
        )}
        {distance != null && (
          <div>
            <Distance value={distance} labelSpecifier="line" />
          </div>
        )}
      </>
    );
  }
}

AddressDistance.propTypes = {
  trader: PropTypes.shape({
    address: PropTypes.shape({
      distance: PropTypes.number,
    }),
  }),
};
export default connect(state => ({
  coordinates: pick(['lat', 'lng'], state.traderSearch.query),
}))(AddressDistance);
